<script setup lang="ts">
import { computed } from 'vue'

type Props = {
    maxItemPerRow?: number
    gap?: number
    rowGap?: number
}
const props = withDefaults(defineProps<Props>(), { maxItemPerRow: 1 })
const calculatedGap = computed(() => `${props.gap}px`)
const calculatedRowGap = computed(() => `${props.rowGap ?? props.gap}px`)
</script>

<template>
    <div class="grid-class">
        <slot />
    </div>
</template>

<style scoped lang="scss">
.grid-class {
    display: grid;
    grid-template-columns: repeat(v-bind(maxItemPerRow), 1fr);
    grid-gap: v-bind(calculatedGap);
    row-gap: v-bind(calculatedRowGap);
}
</style>
