<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { EVENT_CHECKPOINT_ROUTES, EVENTS_ROUTES } from '@/router/routes'
import { buildDetectionArray } from '@/socket/socketUtils'
import store from '@/store'
import { ECheckpointActions } from '@/store/checkpointStore/CheckpointStoreTypes'
import { EDetectionActions } from '@/store/detectionStore/DetectionStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { ECheckpointsPerm } from '@/types/permissions/CheckpointsPermissionsEnum'
import type { SocketCheckpointDetectionModel } from '@/types/socket/SocketDetectionModel'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CheckpointCardMode from './CheckpointsTable/CheckpointCardMode.vue'
import EventCreateCheckpoint from './CreateCheckpoint/EventCreateCheckpoint.vue'

type Props = {
    currentTabIndex: number
    partnerConfig?: PartnerModel
    detections: SocketCheckpointDetectionModel[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
    (e: 'goTo', value?: CheckpointModel): void
    (e: 'checkpointUpdate', value?: SocketCheckpointDetectionModel[]): void
    (e: 'refreshDevices', associates: number[]): void
}
const emit = defineEmits<Emits>()

const route = useRoute()
const router = useRouter()
const eventId = computed(() => parseInt(route.params.id.toString()))
const checkpointId = computed(() => parseInt(route.params.checkpointId?.toString()))
const editButtonHref = computed(() => EVENT_CHECKPOINT_ROUTES.buildUrl(eventId.value))

const data = computed(() => store.state.checkpoint)
const latestDetections = ref<SocketCheckpointDetectionModel[]>([])
watch(
    () => props.detections,
    newDetection => (latestDetections.value = newDetection)
)

const getCheckpoints = () =>
    store
        .dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.FETCH_CHECKPOINTS}`, {
            id: eventId.value,
            params: { per_page: -1 }
        })
        .then((res: CheckpointModel[]) => {
            latestDetections.value = res.map(item => ({
                checkpoint_id: item.id,
                detections: []
            }))
            store
                .dispatch(`${EStoreModules.DETECTION}/${EDetectionActions.GET_LATEST_DETECTIONS}`, {
                    checkpoint_ids: res.map(item => item.id)
                })
                .then((resDetections: DetectionModel[]) =>
                    emit(
                        'checkpointUpdate',
                        buildDetectionArray(resDetections, latestDetections.value, 'checkpoint_id')
                    )
                )
        })

onMounted(() => {
    getCheckpoints()
})
const handleDeleteCheckpoint = (checkpoint: CheckpointModel) =>
    store
        .dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.DELETE_CHECKPOINT}`, {
            eventId: eventId.value,
            checkpointId: checkpoint.id,
            name: checkpoint.name
        })
        .then(() => {
            if (checkpointId.value && checkpoint.id === checkpointId.value)
                router.replace(`${EVENTS_ROUTES.buildUrl(eventId.value)}?tab=1`)
            getCheckpoints()
        })

const handleAssociateDevice = () => {
    getCheckpoints()
    emit(
        'refreshDevices',
        data.value.data.checkpoints.flatMap(
            item => item.associates?.flatMap(associatedDevice => associatedDevice.device_id) ?? []
        ) ?? []
    )
}
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <StackLayout direction="column" :gap="8" class="full-width flex-wrap">
            <PermissionWrapper :permission="ECheckpointsPerm.CHECKPOINTS_EDIT">
                <EventCreateCheckpoint
                    :eventId="eventId"
                    :partnerConfig="partnerConfig"
                    :loading="!!store.state.checkpoint.isLoading"
                    @refresh="getCheckpoints" />
            </PermissionWrapper>
            <CheckpointCardMode
                :currentTabIndex="currentTabIndex"
                :data="data.data.checkpoints"
                :detections="latestDetections"
                :loading="data.isLoading ?? false"
                :eventId="eventId"
                :editButtonHref="editButtonHref"
                :editPermission="ECheckpointsPerm.CHECKPOINTS_EDIT"
                :deletePermission="ECheckpointsPerm.CHECKPOINTS_DELETE"
                @goTo="$emit('goTo', $event)"
                @delete="handleDeleteCheckpoint" />
        </StackLayout>
        <RouterView @refresh="getCheckpoints" @refreshAssociate="handleAssociateDevice" />
    </StackLayout>
</template>
