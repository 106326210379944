<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import { TitleTypography, TypographyItem } from '@/components/Typography'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import {
EBatteryLevelIcons,
EBatteryLevelPluggedIcons,
EBatteryLevelValues
} from '@/types/enum/BatteryLevelEnum'
import { EDeviceStatus, EDeviceStatusColors } from '@/types/enum/DeviceStatusEnum'
import { ESignalLevelIcons } from '@/types/enum/SignalLevelEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { computed } from 'vue'

type Props = {
    title: string
    subtitle?: string
    data: DeviceModel
    status?: AlertSocketMessageModel
}

const props = defineProps<Props>()

const closestBatteryLevel = computed(() =>
    EBatteryLevelValues.find(item => item >= (props.status?.battery ?? 0))
)
const currentBatteryLevel = computed(() =>
    !isNullOrUndefined(props.status?.battery) && !isNullOrUndefined(closestBatteryLevel.value)
        ? props.status?.plugged
            ? EBatteryLevelPluggedIcons[closestBatteryLevel.value!]
            : EBatteryLevelIcons[closestBatteryLevel.value!]
        : props.status?.plugged
        ? {
              label: 'plug-circle-check',
              type: EColors.SUCCESS
          }
        : undefined
)

const isOffline = computed(
    () => (props.status?.status ?? parseInt(props.data.status as string)) === EDeviceStatus.OFFLINE
)

const hasSocketStatus = computed(
    () => !!props.status?.battery || !!props.status?.plugged || !!props.status?.signal
)
</script>

<template>
    <StackLayout class="full-width space-between">
        <TitleTypography :title="title" :subtitle="subtitle" class="device-header" />
        <StackLayout :gap="8" class="align-items-center" v-if="hasSocketStatus && !isOffline">
            <StackLayout direction="column" class="align-items-center">
                <IconItem v-if="status?.signal" :icon="ESignalLevelIcons[status.signal]" />
                <TypographyItem v-if="status?.network_type" :label="status?.network_type" />
            </StackLayout>
            <StackLayout direction="column" class="align-items-center">
                <IconItem
                    :icon="currentBatteryLevel"
                    :type="currentBatteryLevel?.type as Colors"
                    class="battery-level" />
                <TypographyItem
                    v-if="!isNullOrUndefined(status?.battery)"
                    :label="`${status?.battery}%`"
                    :class="`has-text-${currentBatteryLevel?.type}`" />
            </StackLayout>
        </StackLayout>
        <IconItem v-else icon="power-off" :type="EDeviceStatusColors[status?.status ?? 0]" />
    </StackLayout>
</template>

<style scoped lang="scss">
.battery-level {
    &:deep(svg) {
        height: auto;
    }
}
</style>
