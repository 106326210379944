import type { DevicesLocales } from '@/types/lang/locales'

export const devicesEn: DevicesLocales = {
    actions: {
        reassignCheckpoint: 'Reassign a checkpoint',
        attachDeviceToOrganization: 'Attach to a client',
        detachDeviceToOrganization: 'Detach from a client',
        changeDeviceType: 'Change device type'
    },
    api: {
        error: {
            createDevice: 'An error occured while creating the device {name}',
            deleteDevice: 'An error occured while deleting the device {name}',
            fetchDevice: 'An error occured while fetching the device',
            fetchDevices: 'An error occured while fetching the devices',
            updateDevice: 'An error occured while updating the device {name}'
        },
        success: {
            createDevice: 'Device {name} successfully created !',
            deleteDevice: 'Device {name} successfully deleted !',
            fetchDevice: 'Device successfully fetched',
            fetchDevices: 'Devices successfully fetched',
            updateDevice: 'Device {name} successfully updated !'
        }
    },
    buttons: {
        hideAdvanced: 'Hide advanced configuration',
        showAdvanced: 'Show advanced configuration'
    },
    configuration: {
        errors: {
            rangeError: 'Value should be between {min} and {max}',
            excludedError: "Value can't be {excluded}"
        },
        apiKey: 'uRTime API Key',
        apnModem: 'APN Modem',
        apnProtocol: 'APN Protocol',
        apnType: 'APN Type',
        bluetooth: 'Bluetooth',
        buzzer: 'Buzzer',
        connectionMethod: 'Connection method',
        gps: 'GPS',
        gpsPingInterval: 'Interval between GPS fix',
        pingInterval: 'Ping interval',
        pirSensor: 'PIR sensor',
        rfidChannel: 'RFID channel',
        wifiPassword: 'WIFI password',
        wifiSSID: 'WIFI SSID',

        unknownKey: 'Unknown key',
        undefinedDescription: 'Undefined description'
    },
    createDevice: 'Create a device',
    detail: {
        informations: {
            actions: 'Actions',
            informationsTitle: "Device's informations",
            latestDetections: 'Latest detections',
            localization: 'Localization'
        },
        configuration: {
            configurationTitle: 'Configuration',
            connectivityTitle: 'Connectivity',
            miscTitle: 'Misc'
        },
        detections: {
            detectionsTitle: 'Latest detections',
            table: {
                bib: 'Bib',
                checkpoint: 'Checkpoint',
                timestamp: 'Date',
                device: 'Device',
                event: 'Event',
                event_filter: 'Event filter',
                timer_filter: 'Timer filter'
            }
        },
        documents: {
            documentsTitle: 'Documents'
        },
        history: {
            historyTitle: 'Configuration history'
        },
        informationsTitle: 'Informations'
    },
    filters: {
        checkpoint: 'Checkpoint',
        event: 'Event',
        lowBattery: 'Low battery',
        lowSignal: 'Low signal',
        name: 'Name',
        nameOrSerial: 'Name or serial number'
    },
    latestDetections: 'Latest detections',
    model: {
        checkpointName: 'Checkpoint associated',
        description: 'Description',
        deviceName: 'Device name',
        deviceType: 'Device type',
        eventName: 'Event associated',
        firmwareUpdated: 'Firmware updated',
        firmwareVersion: 'Firmware version',
        imeiSAT: 'Imei SAT',
        lastMaintenanceDate: 'Last maintenance date',
        manufactureDate: 'Manufacture date',
        organizationName: 'Owner',
        serialNumber: 'Serial number'
    },
    noDetectionFound: 'No detection found',
    rental: {
        rentalApiSuccess: 'Rental successfully created !',
        rentalApiError: 'Error while creating a rental',
        action: 'Rent device',
        title: 'Rent device to an other organization',
        rentToOrganization: 'Rent to this organization',
        startAt: 'Rental start',
        endAt: 'Rental end'
    },
    status: {
        online: 'Online',
        offline: 'Offline',
        lowBattery: 'Low battery',
        lowSignal: 'Medium signal',
        warning: 'Warning'
    },
    table: {
        alerts: 'Alertes',
        createDevice: 'Create a device',
        deviceType: 'Device type',
        name: 'Nom',
        currentEventTitle: 'Event associated'
    },
    title: {
        checkpointTitle: 'Checkpoint',
        eventTitle: 'Event',
        checkpointAssociatedTitle: 'Checkpoint associated',
        currentEventTitle: 'Event associated',
        noEventAssociated: 'No associated event'
    }
}
