<script setup lang="ts">
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { computed, ref, type InputHTMLAttributes, type PropType } from 'vue'
import CustomField from './CustomField.vue'

const props = defineProps({
    label: { type: String, required: false },
    type: Object as PropType<Colors>,
    placeholder: { type: String },
    modelValue: String,
    message: String,
    required: Boolean,
    inputType: String as PropType<InputHTMLAttributes['type']>,
    hasError: Boolean,
    errorText: String
})
const emit = defineEmits(['change'])

const modelValue = computed({
    get: () => props.modelValue,
    set: value => emit('change', value)
})

const computedType = computed(() =>
    props.hasError ? EColors.DANGER : props.type ?? EColors.PRIMARY
)

const inputRef = ref<HTMLSpanElement | null>(null)

defineExpose({ inputRef })
</script>

<template>
    <CustomField
        :label="label ?? ''"
        :message="errorText"
        :type="computedType"
        :required="required">
        <b-input
            ref="inputRef"
            :placeholder="placeholder ?? label"
            :type="inputType"
            v-model="modelValue"
            v-bind="$attrs"
            custom-class="placeholder" />
    </CustomField>
</template>

<style lang="scss">
.placeholder::placeholder {
    color: var(--color-placeholder);
}

.textarea {
    background-color: var(--color-background-secondary);
    color: var(--color-text);
}
</style>
