<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { EditableDateField, EditableStringField } from '@/components/Fields'
import EditableMultilineField from '@/components/Fields/EditableFields/EditableMultilineField.vue'
import { StackLayout } from '@/components/Layouts'
import type { EventModel } from '@/types/models/EventModel'
import { setObjectValuesFromKeys } from '@/utils/dataUtils'
import { DATE_LONG_FORMAT, formatDate } from '@/utils/dateUtils'
import moment from 'moment'

type Props = {
    data: Partial<EventModel>
    isInEditMode: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: Partial<EventModel>): void
}
const emit = defineEmits<Emits>()
const updateInfos = (value: Partial<EventModel>) =>
    emit('update', setObjectValuesFromKeys(props.data, value))

const handleChangeStartDate = (value: Date) =>
    emit(
        'update',
        setObjectValuesFromKeys(props.data, {
            start_at: value,
            end_at: moment(value).startOf('d').isAfter(moment(props.data.end_at).endOf('d'))
                ? moment(value).endOf('d').utc().toDate()
                : props.data.end_at
        })
    )
</script>

<template>
    <InformationBaseCard
        :label="$t('event.detail.informations.informationsTitle')"
        class="full-width fit-height">
        <StackLayout direction="column" :gap="8" class="full-width">
            <EditableStringField
                :label="$t('event.model.name')"
                :value="data?.name"
                @change="name => updateInfos({ name })"
                boldLabel
                :isInEditMode="isInEditMode"
                required />
            <EditableMultilineField
                :label="$t('event.model.comment')"
                :value="data?.comments"
                @change="comments => updateInfos({ comments })"
                boldLabel
                :isInEditMode="isInEditMode" />
            <EditableDateField
                :label="$t('event.model.startAt')"
                :value="data?.start_at ? formatDate(data.start_at) : undefined"
                @change="handleChangeStartDate(moment($event).startOf('d').utc().toDate())"
                :format="DATE_LONG_FORMAT"
                boldLabel
                :isInEditMode="isInEditMode"
                required />
            <EditableDateField
                :label="$t('event.model.endAt')"
                :value="data?.end_at ? formatDate(data.end_at) : undefined"
                @change="
                    updateInfos({
                        end_at: moment($event).endOf('d').utc().toDate()
                    })
                "
                :format="DATE_LONG_FORMAT"
                boldLabel
                :isInEditMode="isInEditMode"
                required />
        </StackLayout>
    </InformationBaseCard>
</template>
