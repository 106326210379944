<script setup lang="ts">
import TokenService from '@/services/token.service'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import { io } from 'socket.io-client'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { SOCKET_RETRY_DELAY } from './socketUtils'

const DEVICE_URL = `${import.meta.env.VITE_SOCKET_URL ?? window.location.origin}/device`

type Props = {
    device_ids?: number[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: AlertSocketMessageModel[]): void
}
const emit = defineEmits<Emits>()

const socketState = ref<Props>()

const socketDeviceAlerts = ref<AlertSocketMessageModel[]>([])

const socketAlert = io(DEVICE_URL, {
    autoConnect: false,
    retries: 5,
    path: '/ws/device',
    transports: ['websocket'],
    query: {
        device_ids: socketState.value?.device_ids,
        token: TokenService.getLocalAccessToken()
    }
}).on('message', (res: AlertSocketMessageModel) => {
    if (typeof res === 'string' && res === 'Authentication error')
        setTimeout(() => socketAlert.disconnect().connect(), SOCKET_RETRY_DELAY)
    const foundIndex = socketDeviceAlerts.value.findIndex(item => item.device_id === res.device_id)
    if (foundIndex !== -1) socketDeviceAlerts.value[foundIndex] = res
    emit('update', socketDeviceAlerts.value)
})

const setSocket = (device_ids: number[]) => {
    if (
        !device_ids?.every(
            item => socketState.value?.device_ids?.find(deviceId => deviceId === item)
        )
    ) {
        updateSocketState('device_ids', props.device_ids)
        reloadSocket()
    }
}

const reloadSocket = () => {
    if (socketAlert.connected) socketAlert.disconnect().connect()
    else socketAlert.connect()
}

const updateSocketState = (key: string, value?: number[]) => {
    if (value) socketDeviceAlerts.value = value?.map(item => ({ device_id: item }))
    socketState.value = { ...socketState.value, [key]: value }
    socketAlert.io.opts.query = { ...socketAlert.io.opts.query, [key]: value }
}

onMounted(() => setSocket(props.device_ids ?? []))
watch(
    () => props.device_ids,
    newValue => setSocket(newValue ?? []),
    { immediate: true }
)

onBeforeUnmount(() => {
    socketAlert.disconnect()
})
</script>
<template><slot /></template>
