<script setup lang="ts">
import { DeviceDetailPage } from '@/components/DeviceComponents'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import deviceApi from '@/services/device.service'
import deviceConfig from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { ERentalsPerm } from '@/types/permissions/RentalsPermissionsEnum'
import { isEqual } from 'lodash'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { EAlertActions } from '../../store/alertStore/AlertStoreTypes'
import DeviceRentalActions from './components/DeviceRentalActions.vue'

const route = useRoute()
const { t } = useI18n()
const deviceId = ref(route.params.id.toString())
const deviceStore = ref(store.state.device.device)
const loading = ref(false)

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })
const template = ref<DeviceTemplateKeysModel[]>([])
const getDeviceConfig = (typeId: number) =>
    deviceConfig.getDeviceTypeConfig(typeId).then(res => (template.value = res.data.config_keys))

const getDevice = () => {
    loading.value = true
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_API_DEVICE}`, deviceId.value)
        .then((res: DeviceModel) => {
            data.value = res
            deviceStore.value = res
            if (res.device_type_id) getDeviceConfig(res.device_type_id)
        })
        .finally(() => (loading.value = false))
}

onMounted(() => {
    if (typeof deviceId.value === 'string') getDevice()
})

const save = () => {
    if (!isEqual(data.value, deviceStore.value)) {
        loading.value = true
        deviceApi
            .updateDeviceDevice(parseInt(deviceId.value), {
                ...data.value,
                config: data.value.config
            })
            .then(res => {
                data.value = { ...data.value, ...res.data }
                deviceStore.value = { ...deviceStore.value, ...res.data }
                store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                    action: EAlertActions.SUCCESS,
                    message: t(`device.api.success.${EDeviceActions.UPDATE_DEVICE}`, {
                        name: data.value.name
                    })
                })
            })
            .catch(() =>
                store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                    action: EAlertActions.ERROR,
                    message: t(`device.api.error.${EDeviceActions.UPDATE_DEVICE}`, {
                        name: data.value.name
                    })
                })
            )
            .finally(() => {
                loading.value = false
            })
    }
    // TODO : dialog success
}

const updateDeviceValue = (value: Partial<DeviceModel>) => (data.value = value)
</script>

<template>
    <DeviceDetailPage
        :storedData="data"
        :data="data"
        :template="template"
        :editPermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_EDIT"
        @save="save"
        @update="updateDeviceValue"
        @refresh="getDevice"
        :loading="loading">
        <template #actions>
            <PermissionWrapper :permission="ERentalsPerm.RENTALS_EDIT">
                <DeviceRentalActions v-if="!!deviceId" :deviceId="parseInt(deviceId)" />
            </PermissionWrapper>
        </template>
    </DeviceDetailPage>
</template>
