import {
    defaultDeviceTemplateKeysCreateModel,
    type DeviceTemplateKeysCreateModel
} from './DeviceTemplateKeysCreateModel'

export type DeviceTemplateKeysModel = DeviceTemplateKeysCreateModel & {
    id: number
    description_trans: string
    trans: string
}

export const defaultDeviceTemplateKeysModel: DeviceTemplateKeysModel = {
    ...defaultDeviceTemplateKeysCreateModel,
    id: 0,
    description_trans: '',
    trans: ''
}
