import i18n from '@/locales/i18n'
import deviceConfigApi from '@/services/deviceConfig.service'
import { EStoreModules } from '@/store/storeType'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { DeviceConfigCreateModel } from '@/types/models/DeviceConfigCreateModel'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { DeviceTypesModel } from '@/types/models/DeviceTypesModel'
import { isForbiddenOrNotFound } from '@/utils/errorsUtils'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import {
    EDeviceConfigActions,
    EDeviceConfigMutations,
    type DeviceConfigState
} from './DeviceConfigStoreTypes'

const state: Ref<DeviceConfigState> = ref({
    deviceTypes: {
        data: [],

        current_page: 1,
        last_page: 0,
        total: 0,
        to: 0,
        from: 0,
        per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
        isLoading: false
    },
    deviceConfigs: {
        data: [],

        current_page: 1,
        last_page: 0,
        total: 0,
        to: 0,
        from: 0,
        per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
        isLoading: false
    },
    templateKeys: {
        data: [],

        current_page: 1,
        last_page: 0,
        total: 0,
        to: 0,
        from: 0,
        per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
        isLoading: false
    }
})

const getters = {
    getDeviceType: () => state.value.deviceTypes.deviceType,
    getDeviceTypes: () => state.value.deviceTypes.data,

    getDeviceConfig: () => state.value.deviceConfigs.deviceConfig,
    getDeviceConfigs: () => state.value.deviceConfigs.data,

    getTemplateKey: () => state.value.templateKeys.templateKey,
    getTemplateKeys: () => state.value.templateKeys.data
}

const actions = {
    async [EDeviceConfigActions.FETCH_DEVICE_TYPE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        id: string
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceTypes' })
        return await deviceConfigApi
            .getDeviceType(id)
            .then(resp => {
                commit(EDeviceConfigMutations.SET_DEVICE_TYPE, resp.data)
                setDocumentAndTitle(resp.data.name)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDeviceConfigActions.FETCH_DEVICE_TYPE
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceTypes'
                })
            )
    },

    async [EDeviceConfigActions.FETCH_DEVICE_TYPES](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceTypes' })
        return await deviceConfigApi
            .getDeviceTypes(params)
            .then(data => {
                commit(
                    EDeviceConfigMutations.SET_DEVICE_TYPES,
                    (data as PaginationDataType<DeviceTypesModel[]>).data ?? data
                )
                commit(EDeviceConfigMutations.SET_TOTAL, { data, key: 'deviceTypes' })
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDeviceConfigActions.FETCH_DEVICE_TYPES
                })
            })
            .finally(() => {
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceTypes'
                })
            })
    },

    async [EDeviceConfigActions.CREATE_DEVICE_TYPE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: DeviceConfigCreateModel
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceTypes' })
        return await deviceConfigApi
            .createDeviceTypes(data)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EDeviceConfigActions.CREATE_DEVICE_TYPE
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EDeviceConfigActions.CREATE_DEVICE_TYPE
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceTypes'
                })
            )
    },

    async [EDeviceConfigActions.UPDATE_DEVICE_TYPE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, data }: { id: string; data: DeviceConfigModel }
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceTypes' })
        return await deviceConfigApi
            .updateDeviceTypes(id, data)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EDeviceConfigActions.UPDATE_DEVICE_TYPE
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EDeviceConfigActions.UPDATE_DEVICE_TYPE
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceTypes'
                })
            )
    },

    async [EDeviceConfigActions.DELETE_DEVICE_TYPE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, name }: { id: number; name: string }
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceTypes' })
        return await deviceConfigApi
            .deleteDeviceTypes(id)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: EDeviceConfigActions.DELETE_DEVICE_TYPE
                })
            )
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name,
                    key: EDeviceConfigActions.DELETE_DEVICE_TYPE
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceTypes'
                })
            )
    },

    async [EDeviceConfigActions.FETCH_DEVICE_CONFIG](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        id: string
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceConfigs' })
        return await deviceConfigApi
            .getDeviceConfig(id)
            .then(resp => {
                commit(EDeviceConfigMutations.SET_DEVICE_CONFIG, resp.data)
                setDocumentAndTitle(resp.data.name, resp.data.device_type_name)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDeviceConfigActions.FETCH_DEVICE_CONFIG
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceConfigs'
                })
            )
    },

    async [EDeviceConfigActions.FETCH_DEVICE_CONFIGS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceConfigs' })
        return await deviceConfigApi
            .getDeviceConfigs(params)
            .then(data => {
                commit(EDeviceConfigMutations.SET_DEVICE_CONFIGS, data.data)
                commit(EDeviceConfigMutations.SET_TOTAL, { data, key: 'deviceConfigs' })
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDeviceConfigActions.FETCH_DEVICE_CONFIGS
                })
            })
            .finally(() => {
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceConfigs'
                })
            })
    },

    async [EDeviceConfigActions.CREATE_DEVICE_CONFIG](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: DeviceConfigCreateModel
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceConfigs' })
        return await deviceConfigApi
            .createDeviceConfig(data)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EDeviceConfigActions.CREATE_DEVICE_CONFIG
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EDeviceConfigActions.CREATE_DEVICE_CONFIG
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceConfigs'
                })
            )
    },

    async [EDeviceConfigActions.UPDATE_DEVICE_CONFIG](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, data }: { id: string; data: DeviceConfigModel }
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceConfigs' })
        return await deviceConfigApi
            .updateDeviceConfig(id, data)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EDeviceConfigActions.UPDATE_DEVICE_CONFIG
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EDeviceConfigActions.UPDATE_DEVICE_CONFIG
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceConfigs'
                })
            )
    },

    async [EDeviceConfigActions.DELETE_DEVICE_CONFIG](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, name }: { id: number; name: string }
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'deviceConfigs' })
        return await deviceConfigApi
            .deleteDeviceConfig(id)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: EDeviceConfigActions.DELETE_DEVICE_CONFIG
                })
            )
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name,
                    key: EDeviceConfigActions.DELETE_DEVICE_CONFIG
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'deviceConfigs'
                })
            )
    },

    async [EDeviceConfigActions.FETCH_DEVICE_TEMPLATE_KEY](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        id: string
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'templateKeys' })
        return await deviceConfigApi
            .getDeviceTemplateKey(id)
            .then(resp => {
                commit(EDeviceConfigMutations.SET_DEVICE_TEMPLATE_KEY, resp.data)
                setDocumentAndTitle(resp.data.name)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDeviceConfigActions.FETCH_DEVICE_TEMPLATE_KEY
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'templateKeys'
                })
            )
    },

    async [EDeviceConfigActions.FETCH_DEVICE_TEMPLATE_KEYS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'templateKeys' })
        return await deviceConfigApi
            .getDeviceTemplateKeys(params)
            .then(data => {
                commit(EDeviceConfigMutations.SET_DEVICE_TEMPLATE_KEYS, data.data)
                commit(EDeviceConfigMutations.SET_TOTAL, { data, key: 'templateKeys' })
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDeviceConfigActions.FETCH_DEVICE_TEMPLATE_KEYS
                })
            })
            .finally(() => {
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'templateKeys'
                })
            })
    },

    async [EDeviceConfigActions.CREATE_DEVICE_TEMPLATE_KEY](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: DeviceConfigCreateModel
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'templateKeys' })
        return await deviceConfigApi
            .createDeviceTemplateKeys(data)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EDeviceConfigActions.CREATE_DEVICE_TEMPLATE_KEY
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EDeviceConfigActions.CREATE_DEVICE_TEMPLATE_KEY
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'templateKeys'
                })
            )
    },

    async [EDeviceConfigActions.UPDATE_DEVICE_TEMPLATE_KEY](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, data }: { id: string; data: DeviceTemplateKeysModel }
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'templateKeys' })
        return await deviceConfigApi
            .updateDeviceTemplateKeys(id, data)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EDeviceConfigActions.UPDATE_DEVICE_TEMPLATE_KEY
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EDeviceConfigActions.UPDATE_DEVICE_TEMPLATE_KEY
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'templateKeys'
                })
            )
    },

    async [EDeviceConfigActions.DELETE_DEVICE_TEMPLATE_KEY](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, name }: { id: number; name: string }
    ) {
        commit(EDeviceConfigMutations.SET_LOADING, { isLoading: true, key: 'templateKeys' })
        return await deviceConfigApi
            .deleteDeviceTemplateKeys(id)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: EDeviceConfigActions.DELETE_DEVICE_TEMPLATE_KEY
                })
            )
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name,
                    key: EDeviceConfigActions.DELETE_DEVICE_TEMPLATE_KEY
                })
            })
            .finally(() =>
                commit(EDeviceConfigMutations.SET_LOADING, {
                    isLoading: false,
                    key: 'templateKeys'
                })
            )
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`deviceConfig.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`deviceConfig.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
    }
}

const mutations = {
    [EDeviceConfigMutations.SET_DEVICE_TYPES](
        _: DeviceConfigState,
        deviceTypes: DeviceTypesModel[]
    ) {
        state.value.deviceTypes.data = deviceTypes
    },
    [EDeviceConfigMutations.SET_DEVICE_TYPE](_: DeviceConfigState, deviceType: DeviceTypesModel) {
        state.value.deviceTypes.deviceType = deviceType
    },
    [EDeviceConfigMutations.SET_DEVICE_CONFIGS](
        _: DeviceConfigState,
        deviceConfigs: DeviceConfigModel[]
    ) {
        state.value.deviceConfigs.data = deviceConfigs
    },
    [EDeviceConfigMutations.SET_DEVICE_CONFIG](
        _: DeviceConfigState,
        deviceConfig: DeviceConfigModel
    ) {
        state.value.deviceConfigs.deviceConfig = deviceConfig
    },
    [EDeviceConfigMutations.SET_DEVICE_TEMPLATE_KEYS](
        _: DeviceConfigState,
        templateKeys: DeviceTemplateKeysModel[]
    ) {
        state.value.templateKeys.data = templateKeys.map(item => ({
            ...item,
            key: undefined,
            keyName: item.name
        }))
    },
    [EDeviceConfigMutations.SET_DEVICE_TEMPLATE_KEY](
        _: DeviceConfigState,
        templateKey: DeviceTemplateKeysModel
    ) {
        state.value.templateKeys.templateKey = templateKey
    },
    [EDeviceConfigMutations.SET_TOTAL](
        _: DeviceConfigState,
        { data, key }: { data: PaginationDataType<any>; key: keyof DeviceConfigState }
    ) {
        state.value[key].current_page = data.current_page
        state.value[key].total = data.total
        state.value[key].last_page = data.last_page
        state.value[key].to = data.to
        state.value[key].from = data.from
    },
    [EDeviceConfigMutations.SET_LOADING](
        _: DeviceConfigState,
        { isLoading, key }: { isLoading: boolean; key: keyof DeviceConfigState }
    ) {
        state.value[key].isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
