<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { computed, ref, type PropType } from 'vue'
import CustomField from './CustomField.vue'

const props = defineProps({
    label: { type: String, required: false },
    type: Object as PropType<Colors>,
    placeholder: { type: String },
    modelValue: Number,
    required: Boolean,
    disabled: Boolean,
    hasError: Boolean,
    message: String,
    prefix: String,
    suffix: String,
    errorText: String,
    min: Number,
    max: Number
})
const emit = defineEmits(['change'])

const modelValue = computed({
    get: () => props.modelValue,
    set: value => emit('change', value)
})

const handleKeyPress = (event: KeyboardEvent) =>
    emit('change', (event.target as HTMLInputElement).value)

const computedType = computed(() =>
    props.hasError ? EColors.DANGER : props.type ?? EColors.PRIMARY
)
const isFocus = ref(false)

const inputRef = ref<HTMLSpanElement | null>(null)
defineExpose({ inputRef })

const inputWidth = computed(() =>
    !isNullOrUndefined(props.min) && !!props.max && props.placeholder ? '200px' : 'inherit'
)
</script>

<template>
    <CustomField
        :label="label ?? ''"
        :type="computedType"
        :required="required"
        :message="errorText">
        <StackLayout class="align-self-center full-width">
            <span
                v-if="prefix"
                :class="`prefix flex color-text align-items-center ${disabled ? 'disabled' : ''}`">
                {{ prefix }}
            </span>
            <b-numberinput
                ref="inputRef"
                @input="isFocus = true"
                @focus="isFocus = false"
                :controls="false"
                :placeholder="placeholder ?? label"
                v-model="modelValue"
                v-on:keyup.enter="handleKeyPress"
                :class="`${prefix ? 'number-prefix' : ''} ${suffix ? 'number-suffix' : ''}`"
                :disabled="disabled"
                :min="min"
                :max="max"
                v-bind="$attrs">
                <template>n°</template>
            </b-numberinput>
            <span
                v-if="suffix"
                :class="`suffix flex color-text align-items-center ${disabled ? 'disabled' : ''}`">
                {{ suffix }}
            </span>
        </StackLayout>
    </CustomField>
</template>

<style scoped lang="scss">
.prefix {
    background-color: var(--color-background-secondary);
    width: unset;
    padding-left: 8px;
    border: 1px solid $primary;
    border-right: unset;
    border-radius: 4px 0px 0px 4px;

    &.disabled {
        background-color: var(--color-border);
    }
}
.suffix {
    width: unset;
    padding-right: 8px;
    border: 1px solid $primary;
    border-left: unset;
    border-radius: 0px 4px 4px 0px;

    &.disabled {
        background-color: var(--color-border);
    }
}

.b-numberinput {
    background-color: var(--color-background-secondary);
}
.b-numberinput {
    width: 100%;
    & :deep(input[type='number']) {
        text-align: unset;
        min-width: v-bind(inputWidth);
        &:focus {
            clip-path: none;
            box-shadow: none;
        }

        &.number-prefix {
            border-left: unset;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
        }
        &.number-suffix {
            border-right: unset;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
        }

        &::placeholder {
            color: var(--color-placeholder);
        }
    }
}
</style>
