<script setup lang="ts">
import CheckpointAutocomplete from '@/components/Filters/components/CheckpointAutocomplete.vue'
import EventAutocomplete from '@/components/Filters/components/EventAutocomplete.vue'
import checkpointApi from '@/services/checkpoint.service'
import store from '@/store'
import { EFiltersActions } from '@/store/filtersStore/FiltersStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { EventModel } from '@/types/models/EventModel'
import { ref, watch } from 'vue'

type Props = EventCheckpointType & {
    keepEventInStorage?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Omit<EventCheckpointType, 'event'>, event: PointerEvent): void
    (e: 'associates', value?: AssociatedDeviceModel[]): void
}
const emit = defineEmits<Emits>()

const handleSelect = (value: EventCheckpointType, event: PointerEvent) =>
    emit('change', { ...value, device_id: undefined }, event)

const handleSelectEvent = (value: EventModel | undefined, event: PointerEvent) => {
    if (props.keepEventInStorage)
        store.dispatch(
            `${EStoreModules.FILTERS}/${EFiltersActions.SET_FILTER}`,
            value
                ? {
                      event: { id: value.id, name: value.name }
                  }
                : undefined
        )
    handleSelect({ event_id: value?.id, checkpoint_id: undefined }, event)
    getCheckpoints(value?.id)
}
const handleSelectCheckpoint = (value: CheckpointModel | undefined, event: PointerEvent) => {
    handleCheckpointTyping(undefined)
    handleSelect({ checkpoint_id: value?.id }, event)
    emit('associates', value?.associates)
}

const getCheckpoints = (event_id?: number, name?: string) => {
    if (!event_id) return
    checkpointApi
        .getCheckpointsFromEvent(event_id, { per_page: -1, filters: name ? { name } : undefined })
        .then(res => {
            checkpointOptions.value = res.data ?? res
            checkpointInputOptions.value = undefined
            checkpointInputValue.value = undefined
        })
}

const checkpointOptions = ref<CheckpointModel[]>([])

watch(
    () => props.event?.id,
    value => (value ? getCheckpoints(value) : undefined),
    { immediate: true }
)

const handleCheckpointTyping = (value?: string) => {
    checkpointInputValue.value = value
    checkpointInputOptions.value = checkpointOptions.value.filter(item =>
        item.name.toLocaleLowerCase().includes(value?.toLowerCase() ?? '')
    )
}
const checkpointInputValue = ref<string>()
const checkpointInputOptions = ref<CheckpointModel[]>()
</script>

<template>
    <EventAutocomplete
        :event="event"
        @change="(value, event) => handleSelectEvent(value, event)"
        class="full-width" />
    <CheckpointAutocomplete
        :disabled="!event_id"
        :checkpointOptions="checkpointInputOptions ?? checkpointOptions"
        :checkpointId="checkpoint_id"
        :inputValue="checkpointInputValue"
        @typing="handleCheckpointTyping"
        @select="(value, event) => handleSelectCheckpoint(value, event)"
        class="full-width" />
</template>
