<script setup lang="ts">
import { DeviceTablePage } from '@/components/DeviceComponents'
import { TypographyWithPrefix } from '@/components/Typography'
import { ADMIN_DEVICES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdminDeviceFiltersType,
    type AdminDeviceFiltersType
} from '@/types/filters/AdminFilters'
import type { AdvancedDeviceFilters, DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { isCurrentPageOverTotal } from '@/utils/paginationUtils'
import { addToQuery, getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const data = computed(() => store.state.device)
const filters = ref<AdminDeviceFiltersType>({
    ...defaultAdminDeviceFiltersType,
    ...getQueryFilters()
})
const advancedFilters = ref<AdvancedDeviceFilters>({})

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getDevices = () =>
    store.dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_DEVICES}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: getPageSize() ?? data.value.per_page,
        page: data.value.current_page
    })

const goToDetail = (device: DeviceModel) => router.push(ADMIN_DEVICES_ROUTES.buildUrl(device.id))

const handleChangeFilters = (value: Partial<DeviceModel>) => {
    filters.value = { ...filters.value, ...value }
    addToQuery({ ...filters.value, page: 1 })
    data.value.current_page = 1
    getDevices()
}
const handleAdvancedFilters = (value: Partial<DeviceFiltersType & AdvancedDeviceFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })
const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDevices()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getDevices()
}

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDevices()
}

const deleteDevice = (device: DeviceModel) => {
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.DELETE_DEVICE}`, {
            deviceId: device.id,
            name: device.name
        })
        .then(() =>
            isCurrentPageOverTotal(
                data.value.total - 1,
                data.value.per_page,
                data.value.current_page,
                data.value.last_page
            )
                ? handleChangePageNumber(data.value.current_page - 1)
                : getDevices()
        )
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    addToQuery({ ...advancedFilters.value, page: 1 })
    getDevices()
}

const handleRemoveTag = (value: FilterTagModel) => {
    filters.value = { ...filters.value, [value.name]: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name]: undefined }
    addToQuery({ ...filters.value, page: 1 })
}

onBeforeUnmount(() => {
    data.value.current_page = 1
})
</script>

<template>
    <DeviceTablePage
        :data="data"
        :filters="filters"
        :advancedFilters="advancedFilters"
        :sortBy="sort"
        :loading="data.isLoading"
        :editPermission="EAdminPerm.ADMIN_DEVICES"
        :deletePermission="EAdminPerm.ADMIN_DEVICES"
        :createButtonHref="ADMIN_DEVICES_ROUTES.createUrl"
        :editButtonHref="ADMIN_DEVICES_ROUTES.baseUrl"
        @changeAdvancedFilters="handleAdvancedFilters"
        @changeFilters="handleChangeFilters"
        @changePageNumber="handleChangePageNumber"
        @changePageSize="handleChangePageSize"
        @delete="deleteDevice"
        @goToDetail="goToDetail"
        @removeTag="handleRemoveTag"
        @changeSortBy="handleChangeSortBy"
        @search="handleSearch">
        <template #filters>
            <!-- <AdminDeviceFilters :filters="filters" @change="filters = { ...filters, ...$event }" /> -->
        </template>
        <template #cardContent="props">
            <TypographyWithPrefix
                :prefix="$t('device.model.organizationName')"
                :label="props.organization_name ?? $t('common.undefined')"
                boldLabel />
        </template>
    </DeviceTablePage>
</template>
