import type { DateLocales } from '@/types/lang/locales'

export const dateEn: DateLocales = {
    at: 'At {date}',
    date: 'Date',
    dates: 'Dates',
    from: 'From {date}',
    start: 'Start',
    end: 'End',
    to: 'To {date}'
}
