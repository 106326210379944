<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import type { DeviceConfigTemplateKey } from '@/types/device/DeviceConfigTemplateKey'
import { EKeyCategory, EKeyCategoryLabels } from '@/types/enum/KeyCategoryEnum'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { computed, ref } from 'vue'
import DeviceConfigurationItem from './DeviceConfigurationItem.vue'

type Props = {
    data: Partial<DeviceModel>
    isInEditMode: boolean
    showAdvanced: boolean
    config: Record<string, DeviceConfigTemplateKey[]>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', data: Partial<DeviceModel>): void
    (e: 'refresh'): void
    (e: 'hasAdvancedConfig', value: boolean): void
}
const emit = defineEmits<Emits>()

const handleChange = (value: { [key: string]: ExtendedPrimitives | undefined }) => {
    emit('update', { config: { ...props.data.config, ...value } })
}

const filters1Ref = ref()
const filters2Ref = ref()
const hasError = computed(
    () => filters1Ref.value?.configurationHasError || filters2Ref.value?.configurationHasError
)

defineExpose({ hasError })
</script>

<template>
    <StackLayout
        v-if="config[EKeyCategory.FILTER_1] || config[EKeyCategory.FILTER_2]"
        direction="row"
        :gap="16"
        isResponsive
        class="full-width">
        <InformationBaseCard
            v-if="config[EKeyCategory.FILTER_1]"
            v-show="
                filters1Ref?.configLength || (filters1Ref?.advancedConfigLength && showAdvanced)
            "
            :label="EKeyCategoryLabels[EKeyCategory.FILTER_1]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="filters1Ref"
                :data="data.config"
                :template="config[EKeyCategory.FILTER_1]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>
        <InformationBaseCard
            v-if="config[EKeyCategory.FILTER_2]"
            v-show="
                filters2Ref?.configLength || (filters2Ref?.advancedConfigLength && showAdvanced)
            "
            :label="EKeyCategoryLabels[EKeyCategory.FILTER_2]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="filters2Ref"
                :data="data.config"
                :template="config[EKeyCategory.FILTER_2]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>
    </StackLayout>
</template>
