<script setup lang="ts">
import { ADMIN_ROLES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { ERoleActions } from '@/store/roleStore/RoleStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultRoleModel, type RoleModel } from '@/types/models/RoleModel'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import AdministrationRoleDetailView from '../AdministrationRoleDetailView.vue'

const router = useRouter()

const data = ref<Partial<RoleModel>>({ ...defaultRoleModel })

const updateRole = (value: Partial<RoleModel>) => (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(`${EStoreModules.ROLE}/${ERoleActions.CREATE_ROLE}`, data.value)
        .then(() => router.push(ADMIN_ROLES_ROUTES.baseUrl)) // TODO : Alerte
</script>

<template>
    <AdministrationRoleDetailView
        :data="data"
        :buttonLabel="$t('common.create')"
        :loading="!!store.state.role.isLoading"
        hasPermission
        @click="create"
        @update="updateRole" />
</template>
