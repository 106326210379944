// import the api endpoints
import i18n from '@/locales/i18n'
import detectionApi from '@/services/detection.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { isForbiddenOrNotFound } from '@/utils/errorsUtils'
import { getPageSize } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import type { LatestDetectionsParams } from '../../types/models/LatestDetectionsParams'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { EDetectionActions, EDetectionMutations, type DetectionState } from './DetectionStoreTypes'

const state: Ref<PaginationDataType<DetectionState>> = ref({
    data: { detections: [] },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getDetections: () => state.value.data.detections
}

const actions = {
    async [EDetectionActions.FETCH_DETECTIONS](
        { dispatch, commit }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EDetectionMutations.SET_LOADING, true)
        return detectionApi
            .getDetections(params)
            .then(data => {
                commit(EDetectionMutations.SET_DETECTIONS, data.data)
                commit(EDetectionMutations.SET_TOTAL, data)
                return data.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: [EDetectionActions.FETCH_DETECTIONS]
                })
            })
            .finally(() => commit(EDetectionMutations.SET_LOADING, false))
    },

    async [EDetectionActions.GET_LATEST_DETECTIONS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: LatestDetectionsParams
    ) {
        commit(EDetectionMutations.SET_LOADING, true)
        return await detectionApi
            .getLatestDetections(data)
            .then(res => res?.data ?? res)
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EDetectionActions.GET_LATEST_DETECTIONS
                })
            })
            .finally(() => {
                commit(EDetectionMutations.SET_LOADING, false)
            })
    },

    async [EDetectionActions.EXPORT_DETECTIONS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EDetectionMutations.SET_LOADING, true)
        return detectionApi
            .getDetectionsCsv(params)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    key: [EDetectionActions.EXPORT_DETECTIONS]
                })
            )
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: [EDetectionActions.EXPORT_DETECTIONS]
                })
            })
            .finally(() => commit(EDetectionMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`detection.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`detection.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EDetectionMutations.SET_DETECTIONS](_: DetectionState, data: DetectionModel[]) {
        state.value.data.detections = data
    },
    [EDetectionMutations.SET_TOTAL](_: DetectionState, data: PaginationDataType<DetectionModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [EDetectionMutations.SET_LOADING]: (_: DetectionState, data: any) => {
        state.value.isLoading = data
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
