import i18n from '@/locales/i18n'
import permissionService from '@/services/permission.service'
import type { PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { isForbiddenOrNotFound } from '@/utils/errorsUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import {
    EPermissionActions,
    EPermissionMutations,
    type PermissionState
} from './PermissionStoreTypes'

const state: Ref<PermissionState> = ref({
    permission: undefined,
    permissions: [],
    isLoading: false,
    total: 0
})

const getters = {
    getPermissions: () => state.value.permissions,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EPermissionActions.FETCH_PERMISSION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        permissionId: number
    ) {
        commit(EPermissionMutations.SET_LOADING, true)
        return await permissionService
            .getPermission(permissionId)
            .then(res => {
                commit(EPermissionMutations.SET_PERMISSION, res.data)
                return res.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EPermissionActions.FETCH_PERMISSION
                })
            })
            .finally(() => commit(EPermissionMutations.SET_LOADING, false))
    },

    async [EPermissionActions.FETCH_PERMISSIONS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType> & { guard_name: string }
    ) {
        commit(EPermissionMutations.SET_LOADING, true)
        return await permissionService
            .getPermissions(params)
            .then(data => {
                commit(EPermissionMutations.SET_PERMISSIONS, data)
                commit(EPermissionMutations.SET_TOTAL, data.length)
                return data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EPermissionActions.FETCH_PERMISSIONS
                })
            })
            .finally(() => commit(EPermissionMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`permission.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`permission.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EPermissionMutations.SET_PERMISSIONS](_: PermissionState, permissions: any) {
        state.value.permissions = permissions
    },

    [EPermissionMutations.SET_PERMISSION](_: PermissionState, permission: any) {
        state.value.permission = permission
    },

    [EPermissionMutations.SET_TOTAL](_: PermissionState, total: number) {
        state.value.total = total
    },

    [EPermissionMutations.SET_LOADING](_: PermissionState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
