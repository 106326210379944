import { EColors } from '@/types/constants/ColorValues'
import type { AnnouncementModel } from '@/types/models/AnnouncementModel'
import type { DocumentationModel } from '@/types/models/DocumentationModel'
import type { WidgetModel } from '@/types/models/WidgetModel'
import { DEVICE_ROUTE } from '../router/routes'
import { buildQueryUrl } from '../utils/queryUtils'
import { generateRandomString } from './mockupUtils'

export const mockupTechAnnouncements: AnnouncementModel[] = [
    {
        title: 'Nouvelle doc MICRO',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus purus nulla, euismod nec commodo vel, faucibus vel mauris. Nulla sodales libero quis metus tincidunt suscipit. '
    },
    {
        title: 'Nouvelle doc 800',
        type: EColors.SUCCESS,
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus purus nulla, euismod nec commodo vel, faucibus vel mauris. Nulla sodales libero quis metus tincidunt suscipit. '
    }
]

export const mockupTechWidgets: WidgetModel[] = [
    {
        title: "Données d'inventaire",
        content: [
            {
                label: "Total d'appareils",
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: 'Appareils en stocks',
                value: 15,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inStock: true })}`
            },
            {
                label: 'Appareils en location',
                value: 5,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inLocation: true })}`
            }
        ]
    },
    {
        title: 'Données de tickets',
        content: [
            {
                label: 'Total de tickets',
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: "Tickets m'étant affectés",
                value: 15,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ assignedToMe: true })}`
            },
            {
                label: 'Tickets en attente de réponse',
                value: 5,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ awaitingResponse: true })}`
            }
        ]
    }
]

export const mockupTechLatestUpdates = [
    "Guide d'installation URTime Micro",
    "Guide d'installation URTime Nano",
    'Résolution problème de connectivité Nano'
]

export const mockupTechDeviceDocs: DocumentationModel[] = [
    { name: 'Rapport de mise à jour et nouvelles fonctionnalités', id: 1 },
    { name: "Guide d'installation URTime Nano", id: 2 },
    { name: 'Résolution problème de connectivité Nano', id: 3 }
]
export const mockupTechDeviceVersions = ['0.0.1', '0.1.3', '1.1.2']
export const mockupTechDeviceSerialNumber = [...new Array(3)].map(() => generateRandomString(10))

export const mockupTechDevicesCards = [
    {
        title: 'U800',
        versions: mockupTechDeviceVersions,
        serialNumbers: mockupTechDeviceSerialNumber,
        documents: mockupTechDeviceDocs
    },
    {
        title: 'U150',
        versions: mockupTechDeviceVersions,
        serialNumbers: mockupTechDeviceSerialNumber,
        documents: mockupTechDeviceDocs
    },
    {
        title: 'Micro',
        versions: mockupTechDeviceVersions,
        serialNumbers: mockupTechDeviceSerialNumber,
        documents: mockupTechDeviceDocs
    },
    {
        title: 'Nano',
        versions: mockupTechDeviceVersions,
        serialNumbers: mockupTechDeviceSerialNumber,
        documents: mockupTechDeviceDocs
    },
    {
        title: 'Clock',
        versions: mockupTechDeviceVersions,
        serialNumbers: mockupTechDeviceSerialNumber,
        documents: mockupTechDeviceDocs
    }
]
