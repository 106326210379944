<script setup lang="ts">
import RolesTable from '@/components/Roles/RolesTable.vue'
import { ORGANIZATIONS_ROLES_ROUTES } from '@/router/routing/organizationRouting/organizationRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { ERoleActions } from '@/store/roleStore/RoleStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { TabHref } from '@/types/components/TabHref'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { RoleModel } from '@/types/models/RoleModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useRouter } from 'vue-router'

type Props = {
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    buttonsHref?: TabHref
}
defineProps<Props>()

const router = useRouter()

const data = computed(() => store.state.organization.roles)
const filters = ref(getQueryFilters())
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getRoles = () =>
    store.dispatch(
        `${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_ORGANIZATION_ROLES}`,
        {
            filters: filters.value,
            sort: getSortByKey(sort.value),
            per_page: getPageSize() ?? data.value.per_page,
            page: data.value.current_page
        }
    )

const goToRoleCreate = () => router.push(ORGANIZATIONS_ROLES_ROUTES.createUrl)
const goToRolesDetail = (role: RoleModel) =>
    router.push(ORGANIZATIONS_ROLES_ROUTES.buildUrl(role.id))
const deleteRole = (role: RoleModel) => {
    store
        .dispatch(`${EStoreModules.ROLE}/${ERoleActions.DELETE_ROLE}`, {
            roleId: role.id,
            name: role.name
        })
        .then(() => getRoles())
}

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getRoles()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getRoles()
}
const handleChangeSortBy = (value: ESortBy) => {
    sort.value = value
    getRoles()
}

onBeforeUnmount(() => {
    data.value.current_page = 1
})
</script>

<template>
    <RolesTable
        :data="{ ...data, data: data.data }"
        :editPermission="editPermission"
        :deletePermission="deletePermission"
        :editButtonHref="buttonsHref?.editButtonHref"
        :createButtonHref="buttonsHref?.createButtonHref"
        @delete="deleteRole"
        @goToCreate="goToRoleCreate"
        @goToDetail="goToRolesDetail"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeSortBy="handleChangeSortBy"
        @search="getRoles" />
</template>
