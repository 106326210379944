<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import OrganizationSelector from '@/components/Selector/components/OrganizationSelector.vue'
import UserFilters from '@/components/User/components/UserFilters.vue'
import type { AdminUserFiltersType } from '@/types/filters/AdminFilters'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { ref } from 'vue'
import { isMobile, isMobileEvent } from '../../../../utils/viewsUtils'

type Props = {
    filters: AdminUserFiltersType
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: AdminUserFiltersType): void
}
const emit = defineEmits<Emits>()

const selectedOrganization = ref<OrganizationModel>()
const handleSelect = (value?: OrganizationModel) => {
    selectedOrganization.value = value
    emit('change', { organization_id: value?.id })
}

const isMobileLayout = ref(isMobile())
isMobileEvent(e => (isMobileLayout.value = e.matches))
</script>

<template>
    <StackLayout :gap="8" class="full-width" isResponsive>
        <UserFilters
            :name="filters.name"
            :email="filters.email"
            @change="emit('change', $event)"
            :class="isMobileLayout ? 'full-width' : 'fit-content'" />
        <OrganizationSelector
            :selectedOrganization="selectedOrganization?.name"
            @select="handleSelect" />
    </StackLayout>
</template>
