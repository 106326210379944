<script setup lang="ts">
import ReactiveLogo from '@/components/Assets/ReactiveLogo.vue'
import { InputField } from '@/components/Fields'
import GuestLayout from '@/components/Layouts/GuestLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import { ClickableTypography } from '@/components/Typography'
import { FORGOT_PASSWORD_ROUTE, ONBOARDING_ROUTE } from '@/router/routes'
import store from '@/store'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const email = ref('')
const password = ref('')
const submitted = ref(false)

const isLoading = computed(() => store.state.authentication.isLoading)

const submitForm = () => {
    // Trigger the form submission
    if (!email.value || !password.value) return (submitted.value = true)
    submitted.value = false
    store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGIN}`, {
        email: email.value,
        password: password.value
    })
}

const errors = computed(() => ({
    emailError: submitted.value && !email.value,
    passwordError: submitted.value && !password.value
}))

const goToResetPassword = () => router.push(FORGOT_PASSWORD_ROUTE)
</script>

<template>
    <FullScreenLoader :isLoading="isLoading" />
    <GuestLayout
        :title="$t('authentication.title.title')"
        :primaryLabel="$t('authentication.login')"
        :secondaryLabel="$t('authentication.title.resetPassword')"
        @clickSecondary="goToResetPassword"
        @clickSubmit="submitForm">
        <template #content>
            <ReactiveLogo width="320" />
            <form method="post" name="login">
                <StackLayout :gap="32" direction="column">
                    <InputField
                        :label="$t('authentication.email')"
                        @keyup.enter="submitForm"
                        v-model="email"
                        @change="value => (email = value)"
                        :placeholder="$t('authentication.email')"
                        inputType="email"
                        required
                        :hasError="errors.emailError" />
                    <InputField
                        :label="$t('authentication.password')"
                        @keyup.enter="submitForm"
                        v-model="password"
                        @change="value => (password = value)"
                        :placeholder="$t('authentication.password')"
                        inputType="password"
                        required
                        :hasError="errors.passwordError"
                        v-bind:password-reveal="true" />
                </StackLayout>
                <ClickableTypography
                    :label="$t('authentication.onboarding.title')"
                    :href="ONBOARDING_ROUTE"
                    class="onboarding-link" />
            </form>
        </template>
    </GuestLayout>
</template>

<style scoped lang="scss">
.onboarding-link {
    place-content: center;
    padding-top: 1em;
    & :deep(a) {
        text-decoration: underline;
        color: $primary;
        text-decoration-color: $primary;
        text-underline-offset: 4px;
    }
}
</style>
