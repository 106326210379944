<script setup lang="ts">
import { BaseTable } from '@/components/Table'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { ref } from 'vue'
import EventDetectionsColumns from './EventDetectionsColumns.vue'

type Props = {
    detections: DetectionModel[]
}
defineProps<Props>()

const table = ref()
</script>

<template>
    <BaseTable v-bind:ref="table" :data="detections">
        <template #columns>
            <EventDetectionsColumns />
        </template>
    </BaseTable>
</template>
