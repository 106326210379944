import type { TechnicianLocales } from '@/types/lang/locales'

export const technicianEn: TechnicianLocales = {
    documentation: {
        latestUpdates: 'Latest updates'
    },
    question: {
        table: {
            createQuestion: 'Create a question'
        },
        model: {
            id: 'ID',
            name: 'Name'
        }
    },
    ticket: {
        table: {
            createTicket: 'Create a ticket',
            markAsRead: 'Mark as read'
        },
        model: {
            id: 'ID',
            name: 'Name',
            title: 'Title',
            type: 'Type',
            statut: 'Statut',
            severity: 'Severity',
            endDate: 'End date',
            serialNumber: 'Serial number Ticket',
            topic: 'Topic',
            startDate: 'Creation Date'
        },
        detail: {
            information: 'Information',
            description: 'Description',
            reassignement: 'Reassignement',
            ticketReassignment: 'Ticket reassignment',
            selectRecipient: 'Select recipient',
            diagnistic: 'Diagnistic',
            resolution: 'Resolution',
            comment: 'Comment',
            device: 'Device',
            deviceName: 'Name of the device',
            send: 'Send',
            nameDevice: 'Nom appareil',
            reassignenment: 'Reassignenment',
            diagnostic: 'Diagnostic',
            informationUser: "User's informations",
            nameUser: 'Name',
            mailUser: 'Mail',
            type: 'Type',
            action: 'Action',
            serialNumberDevice: 'Serial number Device'
        },
        ticket: {
            name: 'Topic Name',
            topic: 'Topic',
            date: 'Date',
            send: 'Send',
            serialNumber: 'Serial number',
            id: 'Id',
            parent_id: 'Id parent',
            trans: 'Translate',
            parent_name: 'Name parent',
            select_parent_id: 'Select parent Id'
        },

        onglet: {
            information: 'Information',
            message: 'Message'
        },
        message: {
            reply: 'Reply',
            placeholderMessage: 'Send a message ...',
            sendReply: 'Send Reply',
            addFile: 'Add file',
            attachment: 'Attachment'
        },
        filter: {
            archived: 'Only archived',
            createDate: 'Create date',
            object: 'Object',
            sortByPeriod: 'Sort by period',
            status: 'Status',
            support: 'Support',
            user: 'Affected user'
        },
        topic: {
            addTopic: 'Add topic',
            nameTopicFr: 'Name topic (fr)',
            nameTopicEn: 'Name topic (en)',
            placeholderNameTopicFr: 'Enter name topic (fr)',
            placeholderNameTopicEn: 'Enter name topic (en)',
            create: 'Create',
            quit: 'Quit',
            update: 'Update',
            updateTopic: 'Update Topic',
            archived: 'filtre avancé',
            object: 'Object'
        }
    }
}
