<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import deviceApi from '@/services/device.service'
import { EColors } from '@/types/constants/ColorValues'
import type { DeviceHistoryModel } from '@/types/models/DeviceHistoryModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { DATETIME_FORMAT } from '@/utils/dateUtils'
import moment from 'moment'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
    data: Partial<DeviceModel>
}
const props = defineProps<Props>()
const { t } = useI18n()

const deviceHistory = ref<DeviceHistoryModel[]>()
const getHistory = () => {
    if (props.data.device_id && !props.data.id) return
    deviceApi
        .getDeviceHistory(props.data.device_id ?? props.data.id!)
        .then(res => (deviceHistory.value = res.data))
}
onMounted(() => {
    getHistory()
})
</script>

<template>
    <InformationBaseCard class="full-width" :label="$t('device.detail.history.historyTitle')">
        <TypographyItem v-if="!deviceHistory?.length" :label="$t('common.noHistory')" />
        <StackLayout v-else direction="column" :gap="2" class="full-width">
            <StackLayout direction="column" v-for="(item, index) in deviceHistory" :key="index">
                <StackLayout :gap="4" class="align-items-center small">
                    <IconItem icon="circle" :type="EColors.WHITE" class="small-icon" />
                    <StackLayout direction="column">
                        <StackLayout class="align-items-center">
                            <TypographyItem :label="item.name" bold class="key-name" />
                            <StackLayout direction="row" :gap="4" class="align-items-center">
                                <IconItem icon="minus" class="small-icon" />
                                <TypographyItem
                                    :label="`${t('common.modifiedBy')} ${item.editor_name}`"
                                    class="modified-by" />
                                <IconItem icon="minus" class="small-icon" />
                                <TypographyItem
                                    :label="`${t('date.at', {
                                        date: moment(item.updated_at).format(DATETIME_FORMAT)
                                    })}`"
                                    class="modified-by" />
                            </StackLayout>
                        </StackLayout>
                        <StackLayout direction="row" :gap="4" class="align-items-center">
                            <TypographyItem :label="`${$t('common.value')} :`" />
                            <TypographyItem :label="item.old_value ?? 'null'" />
                            <IconItem icon="arrow-right" :type="EColors.WHITE" class="small-icon" />
                            <TypographyItem :label="item.new_value" />
                        </StackLayout>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </StackLayout>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.key-name {
    color: $primary;
}
.modified-by {
    color: $primary;
    color: var(--color-text-secondary);
}
.user-name {
    color: var(--color-text-secondary);
}
</style>
