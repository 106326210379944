<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { BaseDialog } from '@/components/Modals/Dialog'
import { TableFilters } from '@/components/Table'
import type {
    AdvancedFiltersEmits,
    AdvancedFiltersProps
} from '@/types/filters/AdvancedFiltersProps'
import { ref } from 'vue'
import FiltersTags from '../Table/FiltersTags.vue'

defineProps<AdvancedFiltersProps>()
const emit = defineEmits<AdvancedFiltersEmits>()

const advancedFiltersOpen = ref(false)
const handleFiltersDialog = (value: boolean) => (advancedFiltersOpen.value = value)
const search = () => {
    handleFiltersDialog(false)
    emit('search')
}
</script>

<template>
    <TableFilters
        :hideSortBy="hideSortBy"
        :showAdvancedFilters="showAdvancedFilters"
        :isResponsiveSmallScreen="isResponsiveSmallScreen"
        :keepInRow="keepInRow"
        :orderOptions="orderOptions"
        :sortBy="sortBy"
        @openFilters="handleFiltersDialog(true)"
        @changeSortBy="$emit('changeSortBy', $event)"
        :filtersDirection="filtersDirection">
        <template #export><slot name="export" /></template>
        <template #filters><slot name="filters" /></template>
        <template #moreFilters><slot name="moreFilters" /></template>
    </TableFilters>
    <FiltersTags
        v-if="advancedFilters && translationPath"
        :data="advancedFilters"
        :translationPath="translationPath"
        @close="$emit('removeTag', $event)" />
    <StackLayout direction="column" :gap="8">
        <slot name="table" />
        <StackLayout :gap="8" class="centered-pagination border-table">
            <slot name="pagination" />
        </StackLayout>
    </StackLayout>
    <BaseDialog
        :title="$t('common.advancedFilters')"
        :open="advancedFiltersOpen"
        :confirmLabel="$t('common.search')"
        :disabled="disabledAdvancedFilters"
        @close="handleFiltersDialog(false)"
        @confirm="search">
        <template #content><slot name="dialogContent" /></template>
    </BaseDialog>
</template>
