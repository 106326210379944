<script setup lang="ts">
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import GridLayout from '@/components/Layouts/GridLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { SocketCheckpointDetectionModel } from '@/types/socket/SocketDetectionModel'
import { computed, ref } from 'vue'
import CheckpointCard from './components/CheckpointCard.vue'

type Props = {
    currentTabIndex: number
    data: CheckpointModel[]
    detections?: SocketCheckpointDetectionModel[]
    loading: boolean
    eventId: number
    partnerConfig?: PartnerModel
    editButtonHref: string
    editPermission: PermissionsEnums
    deletePermission: PermissionsEnums
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
    (e: 'goTo', value?: CheckpointModel): void
    (e: 'delete', checkpoint: CheckpointModel): void
}
defineEmits<Emits>()

const size = useGridSize()
const showAll = ref(false)
const showScroll = computed(() => (props.data.length > size.value ? 'scroll' : 'auto'))
const maxWidth = computed(() => (showAll.value ? 'unset' : '30%'))
const height = computed(() => (showAll.value ? '100%' : 'auto'))
</script>

<template>
    <StackLayout
        v-if="data?.length"
        direction="column"
        :gap="8"
        isResponsiveSmallScreen
        class="full-width">
        <GridLayout
            v-if="showAll"
            :gap="8"
            :maxItemPerRow="size"
            class="relative flex-wrap"
            isResponsiveSmallScreen>
            <CheckpointCard
                v-for="(checkpoint, key) in data"
                :key="key"
                :data="checkpoint"
                :detections="
                    detections?.find(item => item.checkpoint_id === checkpoint.id)?.detections ?? []
                "
                :href="`${editButtonHref}/${checkpoint.id}?tab=1`"
                class="flex full-height full-width color-text checkpoint-card"
                :editPermission="editPermission"
                :deletePermission="deletePermission"
                @delete="$emit('delete', $event)"
                @click="$emit('goTo', $event)" />
        </GridLayout>
        <StackLayout
            v-else
            ref="stackRef"
            :gap="8"
            class="checkpoint-carousel"
            isResponsiveSmallScreen>
            <CheckpointCard
                v-for="(checkpoint, key) in data"
                :key="key"
                :data="checkpoint"
                :detections="
                    detections?.find(item => item.checkpoint_id === checkpoint.id)?.detections ?? []
                "
                :href="`${editButtonHref}/${checkpoint.id}?tab=1`"
                class="flex full-height full-width color-text checkpoint-card"
                :editPermission="editPermission"
                :deletePermission="deletePermission"
                @delete="$emit('delete', $event)" />
        </StackLayout>
        <SecondaryButton
            :label="$t(`checkpoint.buttons.${showAll ? 'hideAll' : 'showAll'}`)"
            @click="showAll = !showAll"
            class="fit-content align-self-center" />
    </StackLayout>
    <StackLayout v-else class="align-self-center no-record">
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>

<style scoped lang="scss">
.checkpoint-card {
    cursor: default;
    max-width: v-bind(maxWidth);
    min-width: 25%;
    height: v-bind(height);
}

.checkpoint-carousel {
    overflow-x: v-bind(showScroll);
    padding-bottom: 8px;
}
</style>
