import type { AccountLocales } from '@/types/lang/locales'

export const accountEn: AccountLocales = {
    informationsTitle: 'Informations',
    rolesTitle: 'Roles',
    changePasswordTitle: 'Change password',

    model: {
        name: 'Name',
        email: 'Email',
        roles: 'Roles',
        password: 'Password',
        confirmPassword: 'Confirm password',
        currentPassword: 'Current password'
    }
}
