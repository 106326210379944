<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { DeviceConfigTemplateKey } from '@/types/device/DeviceConfigTemplateKey'
import { EKeyCategory, EKeyCategoryLabels } from '@/types/enum/KeyCategoryEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { computed, ref } from 'vue'
import DeviceConfigurationItem from './DeviceConfigurationItem.vue'

type Props = {
    data: Partial<DeviceModel>
    isInEditMode: boolean
    showAdvanced: boolean
    config: Record<string, DeviceConfigTemplateKey[]>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', data: Partial<DeviceModel>): void
    (e: 'refresh'): void
    (e: 'hasAdvancedConfig', value: boolean): void
}
const emit = defineEmits<Emits>()

const handleChange = (value: { [key: string]: ExtendedPrimitives | undefined }) => {
    emit('update', { config: { ...props.data.config, ...value } })
}

const connectivityRef = ref()
const configurationRef = ref()
const miscRef = ref()
const hasError = computed(
    () =>
        connectivityRef.value?.configurationHasError ||
        configurationRef.value?.configurationHasError ||
        miscRef.value?.configurationHasError
)
defineExpose({ hasError })
</script>

<template>
    <StackLayout direction="column" :gap="16" isResponsive class="full-width">
        <InformationBaseCard
            v-if="config[EKeyCategory.CONNECTIVITY]"
            v-show="
                connectivityRef?.configLength ||
                (connectivityRef?.advancedConfigLength && showAdvanced)
            "
            class="full-width fit-height"
            :label="EKeyCategoryLabels[EKeyCategory.CONNECTIVITY]">
            <DeviceConfigurationItem
                ref="connectivityRef"
                :data="data.config"
                :template="config[EKeyCategory.CONNECTIVITY]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>
        <InformationBaseCard
            v-if="config[EKeyCategory.CONFIGURATION]"
            v-show="
                configurationRef?.configLength ||
                (configurationRef?.advancedConfigLength && showAdvanced)
            "
            :label="EKeyCategoryLabels[EKeyCategory.CONFIGURATION]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="configurationRef"
                :data="data.config"
                :template="config[EKeyCategory.CONFIGURATION]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>

        <InformationBaseCard
            v-if="config[EKeyCategory.MISC]"
            v-show="miscRef?.configLength || (miscRef?.advancedConfigLength && showAdvanced)"
            :label="EKeyCategoryLabels[EKeyCategory.MISC]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="miscRef"
                :data="data.config"
                :template="config[EKeyCategory.MISC]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>
    </StackLayout>
</template>
