import type { RecentlyVisitedData } from '@/hooks/useRecentlyVisited'
import i18n from '@/locales/i18n'
import router, { BASE_URL, LOGIN_URL } from '@/router'
import authApi from '@/services/auth.service'
import TokenService from '@/services/token.service'
import { EHandler } from '@/types/enum/HandlerEnum'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import type { ResetPwdModel } from '@/types/models/ResetPwdModel'
import { defaultUserModel, type UserDataModel, type UserModel } from '@/types/models/UserModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { ref, type Ref } from 'vue'
import { type Commit, type Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { EAuthActions, EAuthMutations, type AuthState } from './AuthStoreTypes'

const user = getItemFromStorage('user')
const state: Ref<AuthState> = ref({
    userData: user as UserDataModel,
    me: defaultUserModel,
    isLoading: false
})

const actions = {
    [EAuthActions.ME]: ({ commit }: { commit: Commit }) => {
        authApi.me().then(res => {
            commit(EAuthMutations.SET_ME, res)
            if (
                res.organization_id !==
                (getItemFromStorage(ELocalStorage.RECENTLY) as RecentlyVisitedData)?.organization_id
            )
                dispatchEvent(
                    new CustomEvent('recentlyVisited', {
                        bubbles: true,
                        detail: null
                    })
                )
            TokenService.setUser(res)
        })
    },
    [EAuthActions.LOGIN]: (
        { dispatch, commit }: { commit: Commit; dispatch: Dispatch },
        userData: UserDataModel
    ) => {
        commit(EAuthMutations.SET_IS_LOADING, true)
        commit(EAuthMutations.LOGIN_REQUEST, { userData })
        if (TokenService.isImpersonateUser()) TokenService.removeImpersonateUser()
        authApi
            .login(userData)
            .then(accessToken => {
                dispatch(EHandler.HANDLE_SUCCESS, { key: EAuthActions.LOGIN })
                commit(EAuthMutations.LOGIN_SUCCESS, accessToken)
                router.push(BASE_URL)
            })
            .catch(error => {
                dispatch(EHandler.HANDLE_ERROR, { key: EAuthActions.LOGIN })
                commit(EAuthMutations.LOGIN_FAILURE, error)
            })
            .finally(() => commit(EAuthMutations.SET_IS_LOADING, false))
    },
    [EAuthActions.LOGOUT]: ({ commit }: { commit: Commit }) => {
        commit(EAuthMutations.SET_IS_LOADING, true)
        if (TokenService.getImpersonateUser()) TokenService.swapImpersonateUser()
        authApi.logout()
        commit(EAuthMutations.SET_IS_LOADING, false)
        commit(EAuthMutations.LOGOUT)

        router.replace(LOGIN_URL)
    },
    [EAuthActions.FORGOT_PASSWORD]: async (
        { dispatch, commit }: { commit: Commit; dispatch: Dispatch },
        email: string
    ) => {
        commit(EAuthMutations.SET_IS_LOADING, true)
        const res = await authApi.sendResetLink(email).then(
            response => {
                dispatch(EHandler.HANDLE_SUCCESS, { key: EAuthActions.FORGOT_PASSWORD })
                return response
            },
            error => {
                if (error === 21) {
                    commit(EAuthMutations.LOGIN_FAILURE, error)
                    dispatch(EHandler.HANDLE_ERROR, { key: EAuthActions.FORGOT_PASSWORD })
                }
                return error
            }
        )
        commit(EAuthMutations.SET_IS_LOADING, false)
        return res
    },
    [EAuthActions.RESET_PASSWORD]: (
        { dispatch, commit }: { commit: Commit; dispatch: Dispatch },
        reset: ResetPwdModel
    ) => {
        commit(EAuthMutations.SET_IS_LOADING, true)
        return authApi
            .resetPassword(reset)
            .then(
                response => {
                    dispatch(EHandler.HANDLE_SUCCESS, { key: EAuthActions.RESET_PASSWORD })
                    return response
                },
                error => {
                    commit(EAuthMutations.LOGIN_FAILURE, error)
                    dispatch(EHandler.HANDLE_ERROR, { key: EAuthActions.RESET_PASSWORD })
                }
            )
            .finally(() => commit(EAuthMutations.SET_IS_LOADING, false))
    },
    [EAuthActions.REFRESH_TOKEN]: ({ commit }: { commit: Commit }, accessToken: string) =>
        commit(EAuthMutations.REFRESH_TOKEN, accessToken),

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`authentication.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`authentication.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
    }
}

const mutations = {
    [EAuthMutations.LOGIN_REQUEST]: (_: AuthState, accessToken: string) => {
        state.value.accessToken = accessToken
        state.value.loggedIn = false
    },
    [EAuthMutations.LOGIN_SUCCESS]: (_: AuthState, accessToken: string) => {
        state.value.accessToken = accessToken
        state.value.loggedIn = true
    },
    [EAuthMutations.LOGIN_FAILURE]: () => {
        state.value.userData = null
        state.value.accessToken = undefined
        state.value.loggedIn = false
    },
    [EAuthMutations.LOGOUT]: () => {
        state.value.userData = null
        state.value.accessToken = undefined
        state.value.loggedIn = false
    },
    [EAuthMutations.REFRESH_TOKEN]: (_: AuthState, payload: any) => {
        state.value.loggedIn = true
        state.value.userData = payload.userData
        state.value.accessToken = payload.accessToken
    },
    [EAuthMutations.SET_USER]: (_: AuthState, accessToken: string) => {
        state.value.accessToken = accessToken
    },
    [EAuthMutations.SET_ME]: (_: AuthState, me: UserModel) => {
        state.value.me = me
    },
    [EAuthMutations.SET_IS_LOADING]: (_: AuthState, isLoading: boolean) => {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
