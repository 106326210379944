<script setup lang="ts">
import { DeviceDetailPage } from '@/components/DeviceComponents'
import DeviceBaseActions from '@/components/DeviceComponents/components/DeviceBaseActions.vue'
import { ClickableTypography, TypographyItem, TypographyWithPrefix } from '@/components/Typography'
import { ADMIN_ORGANIZATIONS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import deviceConfig from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { isEqual } from 'lodash'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DeviceTypeActionComponent from './components/DeviceTypeActionComponent.vue'

const route = useRoute()
const deviceStore = ref(store.state.device.device)

const deviceId = ref(route.params.id?.toString() ?? route.params.device_id?.toString())

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })
const template = ref<DeviceTemplateKeysModel[]>([])
const getDeviceConfig = (typeId: number) =>
    deviceConfig.getDeviceTypeConfig(typeId).then(res => (template.value = res.data.config_keys))

const getDevice = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_DEVICE}`, deviceId.value)
        .then(res => {
            data.value = res
            deviceStore.value = res
            if (res.device_type_id) getDeviceConfig(res.device_type_id)
        })
onMounted(() => {
    if (typeof deviceId.value === 'string') getDevice()
})

const save = () => {
    if (!isEqual(data.value, deviceStore.value))
        store
            .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_DEVICE}`, {
                deviceId: deviceId.value,
                deviceData: { ...data.value, roles: ['device'] }
            })
            .then(res => {
                data.value = { ...data.value, ...res }
                deviceStore.value = { ...data.value, ...res }
            })
}
const updateDeviceValue = (value: Partial<DeviceModel>) => (data.value = value)
</script>

<template>
    <DeviceDetailPage
        :storedData="data"
        :data="data"
        :template="template"
        :editPermission="EAdminPerm.ADMIN_DEVICES"
        @save="save"
        @update="updateDeviceValue"
        :loading="store.state.device.isLoading">
        <template #informationsContent>
            <TypographyWithPrefix
                v-if="data.organization_name && data.organization_id"
                :prefix="$t('device.model.organizationName')">
                <template #edit>
                    <ClickableTypography
                        v-if="data.organization_name"
                        :label="data.organization_name"
                        :href="ADMIN_ORGANIZATIONS_ROUTES.buildUrl(data.organization_id)" />
                    <TypographyItem v-else :label="$t('common.undefined')" />
                </template>
            </TypographyWithPrefix>
        </template>
        <template #informationsActions>
            <DeviceTypeActionComponent :data="data" @refresh="getDevice()" />
        </template>
        <template #actions>
            <DeviceBaseActions
                :deviceId="parseInt(deviceId)"
                :organizationId="data.organization_id"
                :organizationName="data.organization_name"
                @refresh="getDevice()" />
        </template>
    </DeviceDetailPage>
</template>
