import i18n from '@/locales/i18n'
import apiService from '@/services/api.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { ApiModel } from '@/types/models/ApiModel'
import { isForbiddenOrNotFound } from '@/utils/errorsUtils'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { EApiActions, EApiMutations, type ApiState } from './ApiStateType'

const state: Ref<PaginationDataType<ApiState>> = ref({
    data: { apis: [] },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
    isLoading: false
})

const getters = {
    getApis: () => state.value.data.apis,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EApiActions.FETCH_API](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        apiId: string
    ) {
        commit(EApiMutations.SET_LOADING, true)
        const res = await apiService
            .getApi(apiId)
            .then(resp => {
                commit(EApiMutations.SET_API, resp.data)
                setDocumentAndTitle(resp.data.name)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EApiActions.FETCH_API
                })
            })
            .finally(() => commit(EApiMutations.SET_LOADING, false))
        return res
    },

    [EApiActions.FETCH_APIS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EApiMutations.SET_LOADING, true)
        apiService
            .getApis(params)
            .then(data => {
                commit(EApiMutations.SET_APIS, data.data)
                commit(EApiMutations.SET_TOTAL, data)
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EApiActions.FETCH_APIS
                })
            })
            .finally(() => {
                commit(EApiMutations.SET_LOADING, false)
            })
    },

    async [EApiActions.CREATE_API](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        apiData: ApiModel
    ) {
        commit(EApiMutations.SET_LOADING, true)
        return await apiService
            .createApi(apiData)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: apiData.name,
                    key: EApiActions.CREATE_API
                }).catch(() => {
                    throw dispatch(EHandler.HANDLE_ERROR, {
                        name: apiData.name,
                        key: EApiActions.CREATE_API
                    })
                })
            )
            .finally(() => {
                commit(EApiMutations.SET_LOADING, false)
            })
    },

    async [EApiActions.UPDATE_API](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { apiId, apiData }: { apiId: string; apiData: any }
    ) {
        commit(EApiMutations.SET_LOADING, true)
        return await apiService
            .updateApi(apiId, apiData)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: apiData.name,
                    key: EApiActions.UPDATE_API
                })
                commit(EApiMutations.SET_API, res.data)
                return res.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: apiData.name,
                    key: EApiActions.UPDATE_API
                })
            })
            .finally(() => {
                commit(EApiMutations.SET_LOADING, false)
            })
    },

    [EApiActions.DELETE_API](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, name }: { id: string; name: string }
    ) {
        commit(EApiMutations.SET_LOADING, true)
        return apiService
            .deleteApi(id)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: EApiActions.DELETE_API
                })
                return res
            })
            .catch(() => () => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name,
                    key: EApiActions.DELETE_API
                })
            })
            .finally(() => {
                commit(EApiMutations.SET_LOADING, false)
            })
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`api.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`api.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EApiMutations.SET_APIS](_: ApiState, apis: ApiModel[]) {
        state.value.data.apis = apis
    },
    [EApiMutations.SET_API](_: ApiState, api: ApiModel) {
        state.value.data.api = api
    },
    [EApiMutations.SET_TOTAL](_: ApiState, data: PaginationDataType<ApiModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [EApiMutations.SET_LOADING](_: ApiState, isLoading: any) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
