import { API_PREFIX, DEVICE_PREFIX } from '@/config/api'
import api from '@/helpers/api'
import auth from '@/helpers/auth'
import device from '@/helpers/device'
import { EVENTS_ROUTES } from '@/router/routes'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { FilterType } from '@/types/filters/FilterType'
import type { DeviceHistoryModel } from '@/types/models/DeviceHistoryModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { AxiosResponse } from 'axios'

const DEVICE_API_PREFIX = `${API_PREFIX}/devices`
const DEVICE_SERVICE_PREFIX = `${DEVICE_PREFIX}/devices`

const EVENT_DEVICE_PREFIX = (eventId: number) => `${EVENTS_ROUTES.buildUrl(eventId)}/devices`

const deviceApi = {
    getDevice: (id: number): Promise<AxiosResponse<DeviceModel>> =>
        auth.get(`${DEVICE_SERVICE_PREFIX}/${id}`),
    getDevices: (
        params: Partial<PaginationType & FilterType<DeviceFiltersType>>
    ): Promise<PaginationDataType<DeviceModel[]>> =>
        auth
            .get(`${DEVICE_SERVICE_PREFIX}${buildQueryUrl(params)}`)
            .then(response => response.data),
    createDevice: (deviceData: any) => auth.post(DEVICE_SERVICE_PREFIX, deviceData),
    updateDevice: (
        id: number,
        deviceData: Partial<DeviceModel>
    ): Promise<AxiosResponse<DeviceModel>> =>
        auth.patch(`${DEVICE_SERVICE_PREFIX}/${id}`, deviceData),
    deleteDevice: (id: number) => auth.delete(`${DEVICE_SERVICE_PREFIX}/${id}`),

    getDevicesTypes: (params?: Partial<PaginationType & DeviceFiltersType>) =>
        auth
            .get(`${DEVICE_SERVICE_PREFIX}/device-types${buildQueryUrl(params)}`)
            .then(response => response.data),
    getApiDevice: (id: number): Promise<AxiosResponse<DeviceModel>> =>
        api.get(`${DEVICE_API_PREFIX}/${id}`),
    updateApiDevice: (id: number, data: DeviceModel): Promise<AxiosResponse<DeviceModel>> =>
        api.patch(`${DEVICE_API_PREFIX}/${id}`, data),

    getDeviceConfig: (id: number): Promise<AxiosResponse<object>> =>
        device.get(`${DEVICE_SERVICE_PREFIX}/${id}/config`),
    updateDeviceConfig: (id: number, data: object): Promise<AxiosResponse<DeviceModel>> =>
        device.patch(`${DEVICE_SERVICE_PREFIX}/${id}`, data),
    updateDeviceDevice: (id: number, data: object): Promise<AxiosResponse<DeviceModel>> =>
        device.patch(`${DEVICE_SERVICE_PREFIX}/${id}`, data),
    getDeviceHistory: (id: number): Promise<AxiosResponse<DeviceHistoryModel[]>> =>
        device.get(`${DEVICE_SERVICE_PREFIX}/${id}/history`),

    getEventDevices: (eventId: number): Promise<AxiosResponse<DeviceModel[]>> =>
        device.get(EVENT_DEVICE_PREFIX(eventId))
}

export default deviceApi
