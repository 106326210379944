<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import OrganizationSelector from '@/components/Selector/components/OrganizationSelector.vue'
import { TypographyItem } from '@/components/Typography'
import type { OrganizationModel } from '@/types/models/OrganizationModel'

type Props = {
    selectedOrganization?: string
    organizationName?: string
}
defineProps<Props>()
type Emits = {
    (e: 'change', organizationValue: string): void
    (e: 'select', organizationValue: OrganizationModel): void
}
const emit = defineEmits<Emits>()

const handleChange = (value: string) => emit('change', value)
const handleSelect = (value: OrganizationModel) => emit('select', value)
</script>

<template>
    <InformationBaseCard class="full-width" :label="$t('user.create.organizationTitle')">
        <StackLayout :gap="8" class="full-width" isResponsiveSmallScreen>
            <InputField
                :label="$t('user.create.newOrganizationName')"
                :modelValue="organizationName"
                @change="handleChange($event)"
                class="full-width" />
            <TypographyItem :label="$t('common.or')" class="align-self-center" />
            <OrganizationSelector
                :selectedOrganization="selectedOrganization"
                @select="handleSelect"
                class="full-width" />
        </StackLayout>
    </InformationBaseCard>
</template>
