import adminRoutes from '@/router/routing/adminRouting/adminRouting'
import authenticationRoutes from '@/router/routing/authenticationRoutes'
import detectionsRoutes from '@/router/routing/detectionsRoutes'
import devicesRoutes from '@/router/routing/devicesRoutes'
import eventsRoutes from '@/router/routing/eventsRoutes'
import homeRoutes from '@/router/routing/homeRoutes'
import organizationRoutes from '@/router/routing/organizationRouting/organizationRouting'
import supportFAQRoutes from '@/router/routing/supportFAQRoutes'
import technicianRoutes from '@/router/routing/technicianRouting/technicianRouting'
import ticketsRoutes from '@/router/routing/ticketsRoutes'

import { DashboardLayout } from '@/components/Layouts'
import accountRoutes from '@/router/routing/accountRoutes'
import store from '@/store'
import { EAuthMutations } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { type UserDataModel } from '@/types/models/UserModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import moment from 'moment'
import { ref } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

export const BASE_URL = '/'
export const LOGIN_URL = '/login'
export const HOME_URL = '/home'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...authenticationRoutes,
        {
            path: BASE_URL,
            component: DashboardLayout,
            meta: {
                title: ref(''),
                authRequired: true
            },
            children: [
                ...homeRoutes,
                ...organizationRoutes,
                ...devicesRoutes,
                ...detectionsRoutes,
                ...eventsRoutes,
                ...adminRoutes,
                ...ticketsRoutes,
                ...supportFAQRoutes,
                ...technicianRoutes,
                ...accountRoutes
            ]
        },
        {
            path: '/:catchAll(.*)',
            name: 'catchPage',
            redirect: '/'
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    // redirect to login page if the user is not logged yet

    const user = getItemFromStorage<UserDataModel>('user')

    if (!store.state.authentication.loggedIn && user && moment().isBefore(user.expires_at))
        store.commit(`${EStoreModules.AUTHENTICATION}/${EAuthMutations.LOGIN_SUCCESS}`)

    const loggedIn = store.state.authentication.loggedIn

    if (to.meta.authRequired && !loggedIn) return next('/login')
    if (to.meta.guestRoute && loggedIn) return next('/')
    if (
        (!to.meta.permission && !to.meta.permissions) ||
        (to.meta.permissions as string[])?.find(item => user?.permissions?.includes(item)) ||
        user?.permissions?.includes(to.meta.permission as string)
    )
        return next()
    return next(from)
})

router.afterEach(async to => (document.title = to.meta.title ? to.meta.title.value : 'UrTime Hub'))

export default router
