import type { EventsLocales } from '@/types/lang/locales'

export const eventsEn: EventsLocales = {
    api: {
        error: {
            createEvent: 'An error occured while creating the event {name}',
            deleteEvent: 'An error occured while deleting the event {name}',
            fetchEvent: 'An error occured while fetching the event',
            fetchEvents: 'An error occured while fetching the events',
            updateEvent: 'An error occured while updating the event {name}'
        },
        success: {
            createEvent: 'Event {name} successfully created !',
            deleteEvent: 'Event {name} successfully deleted !',
            fetchEvent: 'Event successfully fetched !',
            fetchEvents: 'Events successfully fetched !',
            updateEvent: 'Event {name} successfully edited !'
        }
    },
    createEvent: 'Create event',
    duplicatedEvent: 'Duplicated event',
    button: {
        addCheckpoint: 'Add a checkpoint',
        duplicateEvent: 'Duplicate'
    },
    checkpoint: {
        name: 'Name',
        comment: 'Comment',
        devicesList: 'Devices list',
        noDetectionFound: 'No detection found',
        noDeviceAssociated: 'No device associated'
    },
    detail: {
        informations: {
            informationsTitle: 'Informations',
            eventLocalization: 'Event localization',
            partnerConfigTitle: 'Partner configuration',
            partnerConfigUpdate: 'Partner update'
        },
        checkpoints: {
            checkpointsTitle: 'Checkpoints',
            createCheckpointTitle: 'Create new checkpoint',
            partner: 'Partner'
        },
        detections: {
            detectionsTitle: 'Latest detections'
        },
        devices: {
            devicesTitle: 'Devices'
        }
    },
    detection: {
        table: {
            bib: 'Bib',
            checkpoint: 'Checkpoint',
            timestamp: 'Date',
            device: 'Device',
            event_filter: 'Event filter',
            timer_filter: 'Timer filter'
        }
    },
    filters: {
        archived: 'Archived',
        clientId: 'Client ID',
        date_limit: 'Date',
        end_at: 'End date',
        endAt: 'End of event',
        endDate: 'End date',
        eventName: 'Event name',
        field: 'Field',
        future: 'Future',
        past: 'Past',
        period: 'Period',
        showFinished: 'Show finished events',
        showOngoing: 'Show ongoing events',
        showUpcoming: 'Show upcoming events',
        finished: 'Finished events',
        ongoing: 'Ongoing events',
        upcoming: 'Upcoming events',
        start_at: 'Start of event',
        startAt: 'Start of event',
        startDate: 'Start date'
    },
    model: {
        clientId: 'Client ID',
        comment: 'Comment',
        endAt: 'End date',
        name: 'Event name',
        partnerName: 'Partner',
        partnerId: 'Partner ID',
        startAt: 'Start date',
        tz: 'Timezone'
    },
    table: {
        clientId: 'Client ID',
        comments: 'Comments',
        startDate: 'Start date',
        endDate: 'End date',
        eventName: 'Event name',
        duplicateEvent: 'Are you sure you want to duplicate this event ?'
    }
}
