import store from '@/store'
import { EAuthActions, EAuthMutations } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import type { MenuItemType } from '@/types/MenuItem'
import { EDetectionsPerm } from '@/types/permissions/DetectionsPermissionsEnum'
import { EEventsPerm } from '@/types/permissions/EventsPermissionsEnum'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed, type ComputedRef } from 'vue'
import { ETicketsPerm } from '../types/permissions/TicketsPermissionsEnum'
import {
    DEVICE_ROUTE,
    EVENTS_ROUTE,
    EVENT_DETECTION_ROUTE,
    MY_ACCOUNT_ROUTE,
    TICKETS_ROUTE
} from './routes'
import { MY_ACCOUNT_ROUTE_NAME } from './routing/accountRoutes'
import { adminMenu } from './routing/adminRouting/adminMenu'
import { ECheckpointRoutesNamesValues } from './routing/checkpointsRoutes'
import { EDetectionsRoutesNamesValues } from './routing/detectionsRoutes'
import { EDeviceRoutesNamesValues } from './routing/devicesRoutes'
import { EEventRoutesNamesValues } from './routing/eventsRoutes'
import { organizationMenu } from './routing/organizationRouting/organizationMenu'
import { technicianMenu } from './routing/technicianRouting/technicianMenu'
import { ETicketsRoutesNamesValues } from './routing/ticketsRoutes'

export const menuRoutesTop: ComputedRef<MenuItemType[]> = computed(() => [
    {
        icon: 'gear',
        label: 'menu.devices',
        to: DEVICE_ROUTE,
        permissions:
            !!store.state.authentication.me.organization_id &&
            userHasPermission(EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW),
        names: EDeviceRoutesNamesValues
    },
    {
        icon: 'calendar-days',
        label: 'menu.events',
        to: EVENTS_ROUTE,
        permissions:
            !!store.state.authentication.me.organization_id &&
            userHasPermission(EEventsPerm.EVENTS_VIEW),
        names: [...EEventRoutesNamesValues, ...ECheckpointRoutesNamesValues],
        children: [
            {
                icon: 'eye',
                label: 'menu.detections',
                to: `${EVENT_DETECTION_ROUTE.baseUrl}`,
                permissions: userHasPermission(EDetectionsPerm.DETECTIONS_VIEW),
                names: EDetectionsRoutesNamesValues
            }
        ]
    },
    ...organizationMenu.value,
    ...technicianMenu.value,
    ...adminMenu.value,
    {
        label: 'menu.tickets',
        to: TICKETS_ROUTE,
        names: ETicketsRoutesNamesValues,
        permissions: userHasPermission(ETicketsPerm.TICKETS_VIEW)
    }
])

export const menuRoutesBottom: MenuItemType[] = [
    /* {
        icon: 'comments',
        label: 'menu.supportFaq',
        to: SUPPORTFAQ_ROUTE,
        names: ['supportFaqTable', 'supportFaqDetail']
    } */
]

export const menuAccountItems = computed(() => ({
    myAccount: {
        icon: 'user',
        label: store.state.authentication.me.name,
        to: MY_ACCOUNT_ROUTE,
        names: [MY_ACCOUNT_ROUTE_NAME],
        withoutTranslation: true
    },
    impersonate: {
        icon: 'user-slash',
        label: 'menu.deimpersonate',
        click: () => {
            store
                .dispatch(`${EStoreModules.USER}/${[EUserActions.DEIMPERSONATION_USER]}`)
                .then(() => store.dispatch(`${EStoreModules.AUTHENTICATION}/${[EAuthActions.ME]}`))
        },
        names: []
    },
    disconnect: {
        icon: 'right-from-bracket',
        label: 'menu.logout',
        to: 'logout',
        click: () => store.dispatch(`${EStoreModules.AUTHENTICATION}/${[EAuthMutations.LOGOUT]}`),
        names: []
    }
}))
