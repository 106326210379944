<script setup lang="ts">
import HrefCreateButton from '@/components/Buttons/HrefCreateButton.vue'
import FullTableLayout from '@/components/Layouts/FullTableLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import BaseTable from '@/components/Table/BaseTable.vue'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import TableColumn from '@/components/Table/TableColumn.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import { ADMIN_DEVICE_TYPES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, ESortByAlphabetical, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdminDeviceFiltersType,
    type AdminDeviceFiltersType
} from '@/types/filters/AdminFilters'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { isCurrentPageOverTotal } from '@/utils/paginationUtils'
import { addToQuery, getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

type Props = {
    isCurrentTab: boolean
    permission?: PermissionsEnums
    createButtonHref: string
    editButtonHref: string
}
const props = defineProps<Props>()

const router = useRouter()
const { t } = useI18n()
const data = computed(() => store.state.deviceConfig.deviceTypes)
const filters = ref<AdminDeviceFiltersType>({
    ...defaultAdminDeviceFiltersType,
    ...getQueryFilters()
})
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const columns = {
    name: { field: 'name', label: t('deviceConfig.types.table.name') }
}

const getDeviceConfigs = () =>
    store.dispatch(`${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.FETCH_DEVICE_TYPES}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: getPageSize() ?? data.value.per_page,
        page: props.isCurrentTab ? data.value.current_page : 1
    })

const goToDetail = (deviceConfig: DeviceModel) =>
    router.push(ADMIN_DEVICE_TYPES_ROUTES.buildUrl(deviceConfig.id))
const goToCreate = () => router.push(ADMIN_DEVICE_TYPES_ROUTES.createUrl)

const handleChangeFilters = (value: Partial<DeviceModel>) => {
    filters.value = { ...filters.value, ...value }
    addToQuery({ ...filters.value, page: 1 })
    data.value.current_page = 1
    getDeviceConfigs()
}
const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDeviceConfigs()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getDeviceConfigs()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDeviceConfigs()
}

const deleteDeviceConfig = (device: DeviceModel) => {
    store
        .dispatch(`${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.DELETE_DEVICE_TYPE}`, {
            id: device.id,
            name: device.name
        })
        .then(() =>
            isCurrentPageOverTotal(
                data.value.total - 1,
                data.value.per_page,
                data.value.current_page,
                data.value.last_page
            )
                ? handleChangePageNumber(data.value.current_page - 1)
                : getDeviceConfigs()
        )
}

const table = ref()

watch(
    () => props.isCurrentTab,
    isCurrent => (isCurrent ? addToQuery({ page: data.value.current_page }, true) : null)
)

onBeforeUnmount(() => {
    data.value.current_page = 1
})
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <PermissionWrapper :permission="permission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('deviceConfig.types.buttons.createType')"
                @click="goToCreate" />
        </PermissionWrapper>

        <FullTableLayout
            isResponsiveSmallScreen
            :orderOptions="[...ESortByAlphabetical, ...ESortByDate]"
            :sortBy="sort"
            @changeSortBy="handleChangeSortBy">
            <template #filters></template>

            <template #table>
                <BaseTable v-bind:ref="table" :data="data.data" :loading="data.isLoading">
                    <template #columns>
                        <TableColumn field="name" :label="columns['name'].label" />
                        <TableActionColumn
                            @goTo="goToDetail"
                            @delete="deleteDeviceConfig"
                            :editPermission="permission"
                            :deletePermission="permission"
                            :editButtonHref="editButtonHref" />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>

            <template #dialogContent>
                <slot name="dialogContent" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
