import type { TabWithTablePermissions } from '../components/TabPermissions'

export enum EOrganizationsPerm {
    ORGANIZATIONS_DELETE = 'organizations_delete',
    ORGANIZATIONS_EDIT = 'organizations_edit',
    ORGANIZATIONS_VIEW = 'organizations_view'
}
export enum EOrganizationsUsersPerm {
    ORGANIZATIONS_USERS_DELETE = 'organizations_users_delete',
    ORGANIZATIONS_USERS_EDIT = 'organizations_users_edit',
    ORGANIZATIONS_USERS_VIEW = 'organizations_users_view'
}
export const EOrganizationsUsersTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_DELETE,
    editPermission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT,
    viewPermission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW
}

export enum EOrganizationsDevicesPerm {
    ORGANIZATIONS_DEVICES_DELETE = 'organizations_devices_delete',
    ORGANIZATIONS_DEVICES_EDIT = 'organizations_devices_edit',
    ORGANIZATIONS_DEVICES_VIEW = 'organizations_devices_view'
}

export enum EOrganizationsCustomersPerm {
    ORGANIZATIONS_CUSTOMERS_DELETE = 'organizations_customers_delete',
    ORGANIZATIONS_CUSTOMERS_EDIT = 'organizations_customers_edit',
    ORGANIZATIONS_CUSTOMERS_VIEW = 'organizations_customers_view'
}
export const EOrganizationsCustomersTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_DELETE,
    editPermission: EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_EDIT,
    viewPermission: EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_VIEW
}

export enum EOrganizationsRolesPerm {
    ORGANIZATIONS_ROLES_DELETE = 'organizations_roles_delete',
    ORGANIZATIONS_ROLES_EDIT = 'organizations_roles_edit',
    ORGANIZATIONS_ROLES_VIEW = 'organizations_roles_view'
}
export const EOrganizationsRolesTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_DELETE,
    editPermission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT,
    viewPermission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_VIEW
}
