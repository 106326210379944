import type { AnnouncementModel } from '@/types/models/AnnouncementModel'
import type { WidgetModel } from '@/types/models/WidgetModel'
import { DEVICE_ROUTE } from '../router/routes'
import { buildQueryUrl } from '../utils/queryUtils'

export const mockupAdminAnnouncements: AnnouncementModel[] = []

export const mockupAdminWidgets: WidgetModel[] = [
    {
        title: "Données d'inventaire",
        content: [
            {
                label: "Total d'appareils",
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: 'Appareils en stocks',
                value: 15,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inStock: true })}`
            },
            {
                label: 'Appareils en location',
                value: 5,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inLocation: true })}`
            }
        ]
    },
    {
        title: "Données d'utilisateurs",
        content: [
            {
                label: "Total d'utilisateurs",
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: 'Total de clients',
                value: 15,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inStock: true })}`
            },
            {
                label: "Total d'API",
                value: 5,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inLocation: true })}`
            }
        ]
    }
]
