export const checkpointsFr = {
    api: {
        error: {
            createCheckpoint: 'Erreur lors de la création du checkpoint {name}',
            deleteCheckpoint: 'Erreur lors de la suppression du checkpoint {name}',
            fetchCheckpoint: 'Erreur lors de la récupération du checkpoint',
            fetchCheckpoints: 'Erreur lors de la récupération des checkpoints',
            updateCheckpoint: 'Erreur lors de la modification du checkpoint {name}'
        },
        success: {
            createCheckpoint: 'Création du checkpoint {name} effectuée avec succès !',
            deleteCheckpoint: 'Suppression du checkpoint {name} effectuée avec succès !',
            fetchCheckpoint: 'Récupération du checkpoint effectuée avec succès !',
            fetchCheckpoints: 'Récupération des checkpoints effectuée avec succès !',
            updateCheckpoint: 'Modification(s) du checkpoint {name} effectuée(s) avec succès !'
        }
    },
    buttons: {
        showAll: 'Montrer tous les checkpoints',
        hideAll: 'Cacher les checkpoints supplémentaires'
    },

    model: {
        associates: 'Appareils associés',
        comments: 'Commentaire',
        name: 'Nom du checkpoint'
    },
    detail: {
        associates: 'Associer un appareil',
        associateDevice: "Associer l'appareil",
        noDeviceAssociated: 'Aucun appareil associé',
        dissociateDevice: "Dissocier l'appareil"
    },
    title: {
        associateDeviceTitle: 'Associer des appareils',
        informationsTitle: 'Informations',
        partnerTitle: 'Informations du partenaire'
    }
}
