<script setup lang="ts">
import { BaseTable, TableColumn } from '@/components/Table'
import { ClickableTypography, TypographyItem } from '@/components/Typography'
import { EVENT_CHECKPOINT_ROUTES, EVENTS_ROUTES } from '@/router/routes'
import SocketDetectionsWrapper from '@/socket/SocketDetectionsWrapper.vue'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { SocketDetectionModel } from '@/types/socket/SocketDetectionModel'
import { formatTimestamp } from '@/utils/dateUtils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
    deviceId?: number
}
const props = defineProps<Props>()

const { t } = useI18n()
const columns = {
    bib: { field: 'bib', label: t('device.detail.detections.table.bib') },
    checkpoint: { field: 'checkpoint_name', label: t('device.detail.detections.table.checkpoint') },
    device: { field: 'device_name', label: t('device.detail.detections.table.device') },
    event: { field: 'event_name', label: t('device.detail.detections.table.event') },
    timestamp: { field: 'timestamp', label: t('device.detail.detections.table.timestamp') },
    event_filter: {
        field: 'event_filter',
        label: t('device.detail.detections.table.event_filter')
    },
    timer_filter: { field: 'timer_filter', label: t('device.detail.detections.table.timer_filter') }
}

const table = ref()

const deviceDetections = ref<DetectionModel[]>([])
const handleDetectionsUpdate = (value: SocketDetectionModel) =>
    (deviceDetections.value = [
        ...(value.device_detections
            .find(item => item.device_id === props.deviceId)
            ?.detections.filter(
                item =>
                    !deviceDetections.value.find(
                        detection => detection.timestamp === item.timestamp
                    )
            ) ?? []),
        ...deviceDetections.value
    ])
</script>

<template>
    <SocketDetectionsWrapper
        v-if="!!deviceId"
        :device_ids="[deviceId]"
        @update="handleDetectionsUpdate" />

    <BaseTable v-bind:ref="table" :data="deviceDetections">
        <template #columns>
            <TableColumn :field="columns['bib'].field" :label="columns['bib'].label" />
            <TableColumn :label="columns['event'].label" v-slot="props">
                <ClickableTypography
                    v-if="props.row.event_id"
                    :label="props.row.event_name ?? props.row.event_id"
                    :href="EVENTS_ROUTES.buildUrl(props.row.event_id!)" />
                <TypographyItem v-else-if="props.row.event_name" :label="props.row.event_name" />
                <TypographyItem v-else :label="$t('common.unassociated')" />
            </TableColumn>
            <TableColumn :label="columns['checkpoint'].label" v-slot="props">
                <ClickableTypography
                    v-if="props.row.event_id && props.row.checkpoint_id"
                    :label="props.row.checkpoint_name"
                    :href="`${EVENT_CHECKPOINT_ROUTES.buildUrl(props.row.event_id)}/${
                        props.row.checkpoint_id
                    }`" />
                <TypographyItem
                    v-else-if="props.row.checkpoint_name"
                    :label="props.row.checkpoint_name" />
                <TypographyItem v-else :label="$t('common.unassociated')" />
            </TableColumn>
            <TableColumn :label="columns['timestamp'].label" v-slot="props">
                <TypographyItem :label="formatTimestamp(props.row.timestamp)" />
            </TableColumn>
            <TableColumn
                :field="columns['event_filter'].field"
                :label="columns['event_filter'].label" />
            <TableColumn
                :field="columns['timer_filter'].field"
                :label="columns['timer_filter'].label" />
        </template>
    </BaseTable>
</template>
