//@ts-nocheck

// Style & Icons
import '@mdi/font/css/materialdesignicons.css'
/* import '@ntohq/buefy-next/dist/buefy.css' */
import 'highlight.js/styles/monokai.css'

import './scss/main.css'
import './scss/main.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Buefy from '@ntohq/buefy-next'

import * as Vue from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'

import { type UserDataModel } from '@/types/models/UserModel'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Markdown from 'vue3-markdown-it'
import setupInterceptors from './helpers/interceptor'
import i18n from './locales/i18n'
import router from './router'
import TokenService from './services/token.service'
import store from './store'
import { getItemFromStorage } from './utils/localStorageUtils'

setupInterceptors()

const getEnv = () => {
    if (window.location.host.includes('localhost')) return 'local'
    if (window.location.host.match(/^dev/)) return 'development'
    return process.env.NODE_ENV
}

const app = Vue.createApp(App)

/* Sentry.init({
    app,
    dsn: 'https://e0f936b6a9d74ee36d86e542acd96a41@sentry.rfconception.com/5',
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false
        })
    ],

    environment: getEnv(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        /^localhost/,
        /^https:\/\/dev\.hub\.v2\.urtime\.net/,
        /^https:\/\/hub\.urtime\.net/
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0

    // Other config
}) */

const user = getItemFromStorage<UserDataModel>('user')
await TokenService.setUser(user)

library.add(fab)
library.add(fas)
library.add(far)

app.use(router)
app.use(store)

app.use(Buefy, {
    defaultIconComponent: FontAwesomeIcon,
    defaultIconPack: 'fas'
})
app.use(Markdown)

app.use(i18n)

app.mount('#app')
