<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import eventApi from '@/services/event.service'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { EventModel } from '@/types/models/EventModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { useDebounceFn } from '@vueuse/core'
import { onMounted, ref } from 'vue'

type Props = {
    event?: Partial<EventModel>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: EventModel | undefined, event: PointerEvent): void
}
const emit = defineEmits<Emits>()

const input = ref<string>()
const handleChange = (value: string) => {
    input.value = value
    if (!value) return
    isLoadingEvents.value = true
    debouncedQuestion(value)
}
const isLoadingEvents = ref(false)
const debouncedQuestion = useDebounceFn((value: string) => getEvents(value), EDebounceTime.DEFAULT)
const getEvents = (name?: string) => {
    eventApi
        .getEvents({
            filters: {
                name,
                ongoing: 1,
                upcoming: 1
            },
            sort: getSortByKey(ESortBy.DATE_RECENT)
        })
        .then(res => {
            const currentEvent = getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)
                ?.event
            eventOptions.value =
                currentEvent && currentEvent.id
                    ? [
                          { ...currentEvent, id: parseInt(currentEvent.id?.toString()) },
                          ...res.data.filter(
                              item => item.id !== parseInt((currentEvent?.id ?? '')?.toString())
                          )
                      ]
                    : res.data
            if (props.event && !eventOptions.value.find(item => item.id === props.event?.id))
                eventOptions.value = [{ ...props.event, id: props.event.id }, ...eventOptions.value]
        })
        .finally(() => (isLoadingEvents.value = false))
}

const eventOptions = ref<Partial<EventModel>[]>([])

const handleSelect = (value: EventModel | undefined, event: PointerEvent) => {
    if (!value && input.value !== value) {
        input.value = undefined
        getEvents(value)
    }
    emit('change', value, event)
}

onMounted(() => getEvents())
</script>

<template>
    <AutocompleteField
        :label="$t('device.filters.event')"
        :placeholder="$t('common.typeYourSearch')"
        :modelValue="eventOptions.find(item => item.id === event?.id)?.name"
        :input="input"
        :loading="isLoadingEvents"
        @typing="handleChange"
        @select="(value, event) => handleSelect(value as EventModel, event)"
        :options="eventOptions" />
</template>
