<script setup lang="ts">
import { EditableStringField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import type { DeviceModel } from '@/types/models/DeviceModel'

type Props = {
    data: Partial<DeviceModel>
    isInEditMode: boolean
}
defineProps<Props>()
const emit = defineEmits<{ (e: 'update', data: Partial<DeviceModel>): void }>()

const updateDeviceValue = (key: keyof DeviceModel, value?: string) =>
    emit('update', { [key]: value })
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <EditableStringField
            :label="$t('device.model.deviceName')"
            :value="data?.name"
            boldLabel
            :isInEditMode="isInEditMode"
            @change="value => updateDeviceValue('name', value)"
            required />
        <EditableStringField
            :label="$t('device.model.eventName')"
            :value="data?.event?.name"
            boldLabel
            required />
        <EditableStringField
            :label="$t('device.model.checkpointName')"
            :value="data?.checkpoint?.name"
            boldLabel
            required />
        <slot />
    </StackLayout>
</template>
