import OrganizationCustomersDetailVue from '@/components/Organization/Customers/Detail/OrganizationCustomersDetail.vue'
import OrganizationCustomersGenerateVue from '@/components/Organization/Customers/Generate/OrganizationCustomersGenerate.vue'
import OrganizationUsersCreateVue from '@/components/Organization/Users/Create/OrganizationUsersCreate.vue'
import OrganizationUsersDetailVue from '@/components/Organization/Users/Detail/OrganizationUsersDetail.vue'
import RolesCreate from '@/components/Roles/RolesCreate.vue'
import RolesUpdate from '@/components/Roles/RolesUpdate.vue'
import i18n from '@/locales/i18n'
import {
    EOrganizationsCustomersPerm,
    EOrganizationsPerm,
    EOrganizationsRolesPerm,
    EOrganizationsUsersPerm
} from '@/types/permissions/OrganizationsPermissionsEnum'
import type { RouteType } from '@/types/Route'
import { getValuesFromEnum } from '@/utils/enumUtils'
import OrganizationView from '@/views/OrganizationView/OrganizationView.vue'
import { ref } from 'vue'
import {
    ORGANIZATIONS_CUSTOMERS_ROUTES,
    ORGANIZATIONS_ROLES_ROUTES,
    ORGANIZATIONS_ROUTE,
    ORGANIZATIONS_USERS_ROUTES
} from './organizationRoutes'
import {
    EOrganizationCustomersRoutes,
    EOrganizationRolesRoutes,
    EOrganizationRoutes,
    EOrganizationUsersRoutes
} from './OrganizationRoutesEnum'

export const allOrganizationsPermissions = getValuesFromEnum({
    ...EOrganizationsPerm,
    ...EOrganizationsCustomersPerm,
    ...EOrganizationsRolesPerm,
    ...EOrganizationsUsersPerm
})

const organizationRouting: RouteType[] = [
    {
        path: ORGANIZATIONS_ROUTE,
        name: EOrganizationRoutes.ORGANIZATION_HOME,
        component: OrganizationView,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permissions: [
                EOrganizationsPerm.ORGANIZATIONS_VIEW,
                EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW,
                EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_VIEW,
                EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT
            ]
        }
    },

    {
        path: ORGANIZATIONS_USERS_ROUTES.detailUrl,
        name: EOrganizationUsersRoutes.ORGANIZATION_USERS_DETAIL,
        component: OrganizationUsersDetailVue,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW
        }
    },
    {
        path: ORGANIZATIONS_USERS_ROUTES.createUrl,
        name: EOrganizationUsersRoutes.ORGANIZATION_USERS_CREATE,
        component: OrganizationUsersCreateVue,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT
        }
    },
    {
        path: ORGANIZATIONS_CUSTOMERS_ROUTES.detailUrl,
        name: EOrganizationCustomersRoutes.ORGANIZATION_CUSTOMERS_DETAIL,
        component: OrganizationCustomersDetailVue,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_VIEW
        }
    },
    {
        path: ORGANIZATIONS_CUSTOMERS_ROUTES.generateUrl,
        name: EOrganizationCustomersRoutes.ORGANIZATION_CUSTOMERS_GENERATE,
        component: OrganizationCustomersGenerateVue,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_EDIT
        }
    },

    {
        path: ORGANIZATIONS_ROLES_ROUTES.createUrl,
        name: EOrganizationRolesRoutes.ORGANIZATION_ROLES_CREATE,
        component: RolesCreate,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT
        }
    },
    {
        path: ORGANIZATIONS_ROLES_ROUTES.detailUrl,
        name: EOrganizationRolesRoutes.ORGANIZATION_ROLES_DETAIL,
        component: RolesUpdate,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_VIEW
        }
    }
]

export default organizationRouting
