import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'
import {
    ADMIN_APIS_ROUTES,
    ADMIN_DEVICE_CONFIGURATION_ROUTE,
    ADMIN_DEVICES_ROUTES,
    ADMIN_ORGANIZATIONS_ROUTES,
    ADMIN_ROLES_ROUTES,
    ADMIN_USERS_ROUTES
} from './adminRoutes'
import {
    EAdminApisRoutesValues,
    EAdminDeviceConfigRoutesValues,
    EAdminDevicesRoutesValues,
    EAdminDeviceTemplateKeysRoutesValues,
    EAdminDeviceTemplateRoutesValues,
    EAdminDeviceTypeRoutesValues,
    EAdminOrganizationsDevicesRoutesValues,
    EAdminOrganizationsRoutesValues,
    EAdminOrganizationsUsersRoutesValues,
    EAdminRolesRoutesValues,
    EAdminRoutesValues,
    EAdminUsersRoutesValues
} from './AdminRoutesEnum'

export const adminMenu = computed(() => [
    {
        label: 'menu.administration.title',
        icon: 'users-gear',
        permissions: userHasPermission(EAdminPerm.SUPER_ADMIN),
        names: EAdminRoutesValues,
        children: [
            {
                icon: 'user-group',
                label: 'menu.administration.users',
                to: ADMIN_USERS_ROUTES.baseUrl,
                permissions: userHasPermission(EAdminPerm.ADMIN_USERS),
                names: EAdminUsersRoutesValues
            },
            {
                icon: 'user-tag',
                label: 'menu.administration.roles',
                to: ADMIN_ROLES_ROUTES.baseUrl,
                permissions: userHasPermission(EAdminPerm.ADMIN_ROLES),
                names: EAdminRolesRoutesValues
            },
            {
                icon: 'building-user',
                label: 'menu.administration.organizations',
                to: ADMIN_ORGANIZATIONS_ROUTES.baseUrl,
                permissions: userHasPermission(EAdminPerm.ADMIN_ORGANIZATIONS),
                names: [
                    ...EAdminOrganizationsRoutesValues,
                    ...EAdminOrganizationsUsersRoutesValues,
                    ...EAdminOrganizationsDevicesRoutesValues
                ]
            },
            {
                icon: 'microchip',
                label: 'menu.administration.devices',
                to: ADMIN_DEVICES_ROUTES.baseUrl,
                permissions: userHasPermission(EAdminPerm.ADMIN_DEVICES),
                names: EAdminDevicesRoutesValues
            },
            {
                icon: 'gears',
                label: 'menu.administration.deviceConfiguration',
                to: ADMIN_DEVICE_CONFIGURATION_ROUTE,
                permissions: userHasPermission(EAdminPerm.ADMIN_DEVICES_CONFIG),
                names: [
                    ...EAdminDeviceConfigRoutesValues,
                    ...EAdminDeviceTypeRoutesValues,
                    ...EAdminDeviceTemplateRoutesValues,
                    ...EAdminDeviceTemplateKeysRoutesValues
                ]
            },
            {
                icon: 'circle-nodes',
                label: 'menu.administration.api',
                to: ADMIN_APIS_ROUTES.baseUrl,
                permissions: userHasPermission(EAdminPerm.ADMIN_APIS),
                names: EAdminApisRoutesValues
            }
        ]
    }
])
