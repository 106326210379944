<script setup lang="ts">
import { FullTableLayout } from '@/components/Layouts'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { BaseTable, TablePagination } from '@/components/Table'
import detectionsApi from '@/services/detection.service'
import { ESortBy, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { defaultPaginationDataType, type PaginationDataType } from '@/types/PaginationType'
import { getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import EventDetectionsColumns from './EventDetectionsColumns.vue'

const route = useRoute()
const eventId = ref(parseInt(route.params.id.toString()))

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDetections()
}

const handleChangePageSize = (value: number) => {
    currentEventDetections.value.per_page = value
    currentEventDetections.value.current_page = 1
    getDetections()
}
const handleChangePageNumber = (pageNumber: number) => {
    currentEventDetections.value.current_page = pageNumber
    getDetections()
}
const currentEventDetections = ref<PaginationDataType<DetectionModel[]>>({
    ...defaultPaginationDataType
})
const getDetections = () =>
    detectionsApi
        .getDetections({
            filters: { event_id: eventId.value },
            sort: getSortByKey(sort.value),
            per_page: getPageSize() ?? currentEventDetections.value.per_page,
            page: currentEventDetections.value.current_page
        })
        .then(res => (currentEventDetections.value = res))

onMounted(() => getDetections())
const table = ref()
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <FullTableLayout
            :sortBy="sort"
            @changeSortBy="handleChangeSortBy"
            :orderOptions="ESortByDate"
            isResponsiveSmallScreen>
            <template #table>
                <BaseTable v-bind:ref="table" :data="currentEventDetections?.data ?? []">
                    <template #columns>
                        <EventDetectionsColumns />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :currentPage="currentEventDetections?.current_page"
                    :lastPage="currentEventDetections?.last_page"
                    :total="currentEventDetections?.total"
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
