<script setup lang="ts">
import { InputField, NumberInputField } from '@/components/Fields'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import { GridLayout } from '@/components/Layouts'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { useDebounceFn } from '@vueuse/core'
import { computed, ref } from 'vue'

type Props = {
    filters: DetectionFilters
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
}
const emit = defineEmits<Emits>()

const deviceOptions = ref<AssociatedDeviceModel[] | undefined>([])

const gridSize = useGridSize()

const handleChange = (value: EventCheckpointType) => {
    if (
        (props.filters.event_id ?? undefined) === (value.event_id ?? undefined) &&
        (props.filters.checkpoint_id ?? undefined) === (value.checkpoint_id ?? undefined) &&
        (props.filters.device_id ?? undefined) === (value.device_id ?? undefined)
    )
        return
    emit('change', value)
}

const handleAssociates = (value?: AssociatedDeviceModel[]) => (deviceOptions.value = value)

const handleTyping = (value: Record<string, string | number | undefined>) => {
    debouncedOrganization(value)
}
const debouncedOrganization = useDebounceFn(
    (value: Record<string, string | number | undefined>) => emit('change', value),
    1000
)

const currentDevice = computed(
    () =>
        deviceOptions.value?.find(item => item.device_id === props.filters?.device_id)?.name ??
        undefined
)

const onWheel = () => {}

const handleMinValue = (key: string, value: number, min: number) =>
    handleTyping({ [key]: value < min ? undefined : value })
</script>

<template>
    <GridLayout
        :maxItemPerRow="gridSize"
        :gap="8"
        :rowGap="12"
        class="full-width flex-wrap"
        isResponsive>
        <EventCheckpointAutocomplete
            :event_id="filters.event_id"
            :checkpoint_id="filters.checkpoint_id"
            keepEventInStorage
            @change="handleChange($event)"
            @associates="handleAssociates" />
        <AutocompleteField
            :label="$t('detection.filters.device')"
            :modelValue="currentDevice"
            :options="deviceOptions ?? []"
            :disabled="!filters.checkpoint_id"
            @select="handleChange({ device_id: ($event as DetectionModel)?.device_id })" />
        <NumberInputField
            :label="$t('detection.filters.eventId')"
            :modelValue="filters.event_filter"
            @change="handleMinValue('event_filter', $event, 1)"
            :prefix="$t('unit.number')"
            :min="1"
            @wheel="onWheel" />
        <NumberInputField
            :label="$t('detection.filters.timerId')"
            :modelValue="filters.timer_filter"
            :prefix="$t('unit.number')"
            :min="1"
            @change="handleMinValue('timer_filter', $event, 1)"
            @wheel="onWheel" />

        <InputField
            :label="$t('detection.filters.bib')"
            :modelValue="filters.bib"
            @change="handleTyping({ bib: $event })" />
    </GridLayout>
</template>
