<script setup lang="ts">
import DeviceTableCardMode from '@/components/DeviceComponents/Table/DeviceTableCardMode.vue'
import DeviceTableListMode from '@/components/DeviceComponents/Table/DeviceTableListMode.vue'
import ButtonsGroupFilters from '@/components/Filters/components/ButtonsGroupFilters.vue'
import { FullTableLayout, StackLayout } from '@/components/Layouts'
import { TablePagination } from '@/components/Table'
import SocketDetectionsWrapper from '@/socket/SocketDetectionsWrapper.vue'
import SocketDeviceAlertsWrapper from '@/socket/SocketDeviceAlertsWrapper.vue'
import type { TableEmits, TableProps } from '@/types/components/CommonTableTypes'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { ESortByAlphabetical } from '@/types/enum/SortByEnum'
import { ETableMode, ETableModeIcons, ETableModeValues } from '@/types/enum/TableModeEnum'
import { type AdvancedDeviceFilters, type DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type {
    SocketDetectionModel,
    SocketDeviceDetectionModel
} from '@/types/socket/SocketDetectionModel'
import DevicesAdvancedFilters from '@/views/DevicesView/components/DevicesAdvancedFilters.vue'
import { computed, ref } from 'vue'
import HrefCreateButton from '../Buttons/HrefCreateButton.vue'
import PermissionWrapper from '../Permission/PermissionWrapper.vue'
import DeviceFilters from './Filters/DeviceFilters.vue'

type Props = Omit<
    TableProps<DeviceModel, DeviceFiltersType, AdvancedDeviceFilters>,
    'createButtonHref' | 'editButtonHref'
> & {
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
    createButtonHref?: string
    editButtonHref?: string
}
type Emits = TableEmits<DeviceModel, DeviceFiltersType, AdvancedDeviceFilters>

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const goToCreate = () => emit('goToCreate')
const goToDetail = (device: DeviceModel) => emit('goToDetail', device)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)

const tableMode = ref<number>(ETableMode.CARD)
const tableModes = ETableModeValues.map(item => ({ icon: ETableModeIcons[item] }))

const filtersTags = computed(() =>
    Object.entries({
        lowBattery: props.filters.lowBattery,
        lowSignal: props.filters.lowSignal
    })
        .map(([name, value]) => ({
            name,
            value
        }))
        .filter(item => !!item.value)
)

const handleRemoveTag = (value: FilterTagModel) => {
    if (value.name) emit('removeTag', value)
}

const deviceIds = computed(
    () => !!props.data.data.length && props.data.data?.map(item => item.device_id ?? item.id)
)

const deviceDetections = ref<SocketDeviceDetectionModel[]>([])
const handleDetectionsUpdate = (value: SocketDetectionModel) =>
    (deviceDetections.value = value.device_detections)

const deviceAlerts = ref<AlertSocketMessageModel[]>([])
const handleAlertsUpdate = (value: AlertSocketMessageModel[]) => (deviceAlerts.value = value)
</script>

<template>
    <SocketDeviceAlertsWrapper
        v-if="deviceIds"
        :device_ids="deviceIds"
        @update="handleAlertsUpdate" />
    <SocketDetectionsWrapper
        v-if="deviceIds"
        :device_ids="deviceIds"
        @update="handleDetectionsUpdate" />

    <StackLayout direction="column" :gap="8">
        <PermissionWrapper v-if="createButtonHref" :permission="editPermission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('device.table.createDevice')"
                @click="goToCreate" />
        </PermissionWrapper>
        <FullTableLayout
            isResponsiveSmallScreen
            :orderOptions="ESortByAlphabetical"
            :sortBy="sortBy"
            :advancedFilters="filtersTags"
            translationPath="device.filters"
            @removeTag="handleRemoveTag"
            @search="$emit('search')"
            @changeSortBy="$emit('changeSortBy', $event)">
            <template #filters>
                <DeviceFilters :filters="filters" @change="$emit('changeFilters', $event)" />
                <slot name="filters" />
            </template>
            <template #export>
                <ButtonsGroupFilters
                    :items="tableModes"
                    :value="tableMode"
                    @click="value => (tableMode = value as number)" />
            </template>

            <template #table>
                <DeviceTableListMode
                    v-if="tableMode === ETableMode.LIST"
                    :data="data.data ?? []"
                    :statuses="deviceAlerts"
                    :detections="deviceDetections"
                    :editPermission="editPermission"
                    :deletePermission="deletePermission"
                    :editButtonHref="editButtonHref"
                    :hrefKey="hrefKey"
                    :createButtonHref="createButtonHref"
                    :detailKey="detailKey"
                    @click="goToDetail"
                    @delete="$emit('delete', $event)"
                    :loading="loading" />
                <DeviceTableCardMode
                    v-else
                    :data="data.data ?? []"
                    :statuses="deviceAlerts"
                    :detections="deviceDetections"
                    :editPermission="editPermission"
                    :editButtonHref="editButtonHref"
                    :createButtonHref="createButtonHref"
                    @click="goToDetail"
                    :loading="loading"
                    :showNextEvent="showNextEvent"
                    :showNextCheckpoint="showNextCheckpoint">
                    <template #cardContent="props">
                        <slot name="cardContent" v-bind="props" />
                    </template>
                </DeviceTableCardMode>
            </template>
            <template #pagination>
                <TablePagination
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>

            <template #dialogContent>
                <DevicesAdvancedFilters
                    :filters="advancedFilters"
                    @change="$emit('changeAdvancedFilters', $event)" />
                <slot name="dialogContent" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
