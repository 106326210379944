import router, { HOME_URL } from '@/router'
import store from '@/store'
import { EAuthActions, EAuthMutations } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { TokenType } from '@/types/TokenType'
import type { UserDataModel, UserModel } from '@/types/models/UserModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import moment from 'moment'
import { ELocalStorage } from '../types/enum/LocalStorageEnum'

const TokenService = {
    getLocalRefreshToken: () => {
        const user = JSON.parse(localStorage.getItem('user') ?? '')
        return user?.access_token
    },
    getLocalAccessToken: (): string | undefined =>
        getItemFromStorage<UserDataModel>('user')?.access_token,

    updateLocalAccessToken: (token: string) => {
        const user = getItemFromStorage<UserDataModel>('user')
        localStorage.setItem(
            'user',
            JSON.stringify({
                ...(user ?? {}),
                expires_at: moment(user?.expires_at)
                    .add(user?.expires_in ?? 3600, 'seconds')
                    .toDate(),
                access_token: token
            })
        )
    },
    getUser: () => {
        const user = getItemFromStorage<TokenType>('user')
        if (!user) store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGOUT}`)
        return user
    },
    setUser: async (user: UserDataModel | null) => {
        if (!user) return store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGOUT}`)
        const localUser = getItemFromStorage<UserDataModel>('user')
        localStorage.setItem(
            'user',
            JSON.stringify({
                ...localUser,
                ...user,
                expires_at: moment(user?.expires_at)
                    .add(user?.expires_in ?? 3600, 'seconds')
                    .toDate()
            })
        )

        store.commit(
            `${EStoreModules.AUTHENTICATION}/${EAuthMutations.SET_USER}`,
            user.access_token
        )
    },
    setMe: async (user: UserModel) => {
        localStorage.setItem('me', JSON.stringify(user))
        store.commit(`${EStoreModules.USER}/${EAuthActions.ME}`, user)
    },
    removeUser: () => {
        if (TokenService.isImpersonateUser()) TokenService.removeImpersonateUser()
        localStorage.removeItem('user')
    },

    setImpersonateUser: async (user: TokenType | null) => {
        if (!user) return
        localStorage.setItem('impersonate', JSON.stringify(user))
    },
    removeImpersonateUser: () => localStorage.removeItem('impersonate'),

    getImpersonateUser: () => getItemFromStorage<UserDataModel>('impersonate'),

    isImpersonateUser: () => Boolean(localStorage.getItem('impersonate')),

    swapImpersonateUser: (user = null) => {
        localStorage.removeItem(ELocalStorage.FILTERS)
        localStorage.removeItem(ELocalStorage.RECENTLY)
        window.dispatchEvent(
            new CustomEvent('recentlyVisited', {
                bubbles: true,
                detail: null
            })
        )
        if (!user) {
            TokenService.setUser(TokenService.getImpersonateUser())
            TokenService.removeImpersonateUser()
        } else {
            TokenService.setImpersonateUser(TokenService.getUser())
            TokenService.setUser(user)
        }
        router.push(HOME_URL)
    }
}

// TODO : Check export
export default TokenService
