<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import ResetPasswordButton from '@/components/User/components/ResetPasswordButton.vue'
import UpdateUserView from '@/components/User/Detail/UpdateUserView.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { defaultUserModel, type UserModel } from '@/types/models/UserModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const isLoading = computed(() => store.state.user.isLoading)
const userId = ref(route.params.user_id.toString())
const submitted = ref(false)

const getUser = () =>
    store.dispatch(`${EStoreModules.USER}/${EUserActions.FETCH_USER}`, userId.value).then(res => {
        userValue.value = res
        storedValue.value = res
    })

onMounted(() => {
    if (typeof userId.value === 'string') getUser()
})

const storedValue = ref<UserModel>({ ...defaultUserModel })
const userValue = ref<UserModel>({ ...defaultUserModel })

const updateUserValue = (value: Partial<UserModel>) =>
    (userValue.value = { ...userValue.value, ...value })

const updateUser = () => {
    submitted.value = true
    if (!userValue.value.email || !userValue.value.name) return
    store
        .dispatch(`${EStoreModules.USER}/${EUserActions.UPDATE_USER}`, {
            data: userValue.value,
            userId: userId.value
        })
        .then(() => (submitted.value = false))
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <UpdateUserView
        :data="userValue"
        :submitted="submitted"
        :editPermission="EAdminPerm.ADMIN_USERS"
        :label="$t('common.saveModifications')"
        @click="updateUser"
        @update="updateUserValue">
        <template #update>
            <InformationBaseCard class="full-width fit-height" :label="$t('common.actions')">
                <ResetPasswordButton :email="storedValue.email" />
            </InformationBaseCard>
        </template>
    </UpdateUserView>
</template>
