<script setup lang="ts">
import EditableButtonGroup from '@/components/Buttons/EditableButtonGroup.vue'
import { StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import store from '@/store'
import { ECheckpointActions } from '@/store/checkpointStore/CheckpointStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { ECheckpointsPerm } from '@/types/permissions/CheckpointsPermissionsEnum'
import { isEqual } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { EVENTS_ROUTES } from '../../router/routes'
import CheckpointAssociateDevice from './Detail/CheckpointAssociateDevice.vue'
import CheckpointDetailInformations from './Detail/CheckpointDetailInformations.vue'
import CheckpointPartnerInformations from './Detail/CheckpointPartnerInformations.vue'

type Props = {
    partnerConfig?: PartnerModel
}
defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
    (e: 'refreshAssociate'): void
}
const emit = defineEmits<Emits>()

const route = useRoute()
const router = useRouter()
const eventId = computed(() => parseInt(route.params.id.toString()))
const checkpointId = computed(() => parseInt(route.params.checkpointId?.toString()))
const storedCheckpoint = computed(() => store.state.checkpoint)

const data = ref<Partial<CheckpointModel>>({})

const isInEditMode = ref(false)

const getCheckpoint = () =>
    checkpointId.value
        ? store
              .dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.FETCH_CHECKPOINT}`, {
                  eventId: eventId.value,
                  checkpointId: checkpointId.value
              })
              .then(res => (data.value = { ...res }))
              .catch(() => router.replace(`${EVENTS_ROUTES.buildUrl(eventId.value)}?tab=1`))
        : undefined

onMounted(() => getCheckpoint())
watch(
    () => checkpointId.value,
    () => getCheckpoint()
)

const save = () => {
    if (!isEqual(data.value, storedCheckpoint.value.data.checkpoint))
        store
            .dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.UPDATE_CHECKPOINT}`, {
                eventId: eventId.value,
                checkpointId: checkpointId.value,
                data: data.value
            })
            .then(() => refreshCheckpoint())
}

const refreshCheckpoint = () => {
    getCheckpoint()
    emit('refresh')
}

const refreshAssociate = () => {
    refreshCheckpoint()
    emit('refreshAssociate')
}
</script>

<template>
    <LoadingSpinner :isLoading="!!storedCheckpoint.isLoading" />
    <StackLayout direction="column" :gap="16" isResponsive>
        <PermissionWrapper :permission="ECheckpointsPerm.CHECKPOINTS_EDIT">
            <EditableButtonGroup
                :isInEditMode="isInEditMode"
                @save="save"
                @edit="isInEditMode = $event" />
        </PermissionWrapper>
        <StackLayout direction="row" :gap="8" isResponsive>
            <CheckpointDetailInformations
                :data="data"
                :isInEditMode="isInEditMode"
                @change="data = { ...data, ...$event }" />
            <CheckpointPartnerInformations
                :partnerConfig="partnerConfig"
                :partnerConfigValue="data.partner_config"
                :isInEditMode="isInEditMode"
                @change="data = { ...data, ...$event }" />
        </StackLayout>
        <CheckpointAssociateDevice
            :associates="data?.associates ?? []"
            @refresh="refreshAssociate" />
    </StackLayout>
</template>
