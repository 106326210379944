import moment from 'moment'
import { computed } from 'vue'
import i18n from '../locales/i18n'

export const DATE_FORMAT = computed(() => (i18n.global.locale.value === 'en' ? 'L' : 'DD/MM/YYYY'))
export const DATE_LONG_FORMAT = 'll'
export const DATETIME_FORMAT = computed(() =>
    i18n.global.locale.value === 'en' ? 'L LTS' : 'DD/MM/YYYY HH:mm:ss'
)
export const TIME_FORMAT = computed(() => (i18n.global.locale.value === 'en' ? 'LTS' : 'HH:mm:ss'))

export const dateFormats = [DATE_FORMAT.value, DATE_LONG_FORMAT, DATETIME_FORMAT.value]

export const localeUtcOffset = computed(() => moment().utcOffset())
export const formatDate = (date: Date) =>
    moment(date).subtract(moment(date).utcOffset(), 'm').utc(true).toDate()

export const formatTimestamp = (date: Date | string) =>
    moment.utc(date).local().format(DATETIME_FORMAT.value)
