import { moment } from '@/locales/langUtils'
import type { AnnouncementModel } from '@/types/models/AnnouncementModel'
import type { WidgetModel } from '@/types/models/WidgetModel'
import { DEVICE_ROUTE, EVENTS_ROUTE } from '../router/routes'
import { buildQueryUrl } from '../utils/queryUtils'

export const mockupHomeAnnouncements: AnnouncementModel[] = [
    {
        title: 'Bienvenue sur le hub uRTime',
        content:
            "Bienvenue sur le nouveau Hub uRTime ! N'hésitez pas à nous contacter pour toute remarque ou difficulté rencontrée.", // TODO : Translate
        closable: false,
        fullWidth: true
    }
]

export const mockupHomeWidgets: WidgetModel[] = [
    {
        title: "Données d'inventaire",
        content: [
            {
                label: "Total d'appareils",
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: 'Appareils en stocks',
                value: 15,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inStock: true })}`
            },
            {
                label: 'Appareils en location',
                value: 5,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inLocation: true })}`
            }
        ]
    },
    {
        title: 'Données de courses',
        content: [
            {
                label: 'Courses du jour',
                value: 2,
                link: `${DEVICE_ROUTE}${buildQueryUrl({
                    start_at: moment.value().startOf('d').toDate(),
                    end_at: moment.value().endOf('d').toDate()
                })}`
            },
            {
                label: 'Courses à venir dans la semaine',
                value: 5,
                link: `${EVENTS_ROUTE}${buildQueryUrl({
                    start_at: moment.value().startOf('d').toDate(),
                    end_at: moment.value().endOf('d').add('1', 'week').toDate()
                })}`
            },
            {
                label: 'Courses terminées le dernier weekend',
                value: 2,
                link: `${EVENTS_ROUTE}${buildQueryUrl({
                    start_at: moment.value().endOf('week').startOf('d').subtract(1, 'd').toDate(),
                    end_at: moment.value().endOf('week').endOf('d').toDate()
                })}`
            }
        ]
    },
    {
        title: "Données d'inventaire",
        content: [
            {
                label: "Total d'appareils",
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: 'Appareils en stocks',
                value: 15,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inStock: true })}`
            },
            {
                label: 'Appareils en location',
                value: 5,
                link: `${DEVICE_ROUTE}${buildQueryUrl({ inLocation: true })}`
            }
        ]
    },
    {
        title: 'Données de courses',
        content: [
            {
                label: 'Courses du jour',
                value: 2,
                link: `${DEVICE_ROUTE}${buildQueryUrl({
                    start_at: moment.value().startOf('d').toDate(),
                    end_at: moment.value().endOf('d').toDate()
                })}`
            },
            {
                label: 'Courses à venir dans la semaine',
                value: 5,
                link: `${EVENTS_ROUTE}${buildQueryUrl({
                    start_at: moment.value().startOf('d').toDate(),
                    end_at: moment.value().endOf('d').add('1', 'week').toDate()
                })}`
            },
            {
                label: 'Courses terminées le dernier weekend',
                value: 2,
                link: `${EVENTS_ROUTE}${buildQueryUrl({
                    start_at: moment.value().endOf('week').startOf('d').subtract(1, 'd').toDate(),
                    end_at: moment.value().endOf('week').endOf('d').toDate()
                })}`
            }
        ]
    }
]
