<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { type UserModel } from '@/types/models/UserModel'
import { type PasswordType } from '@/types/PasswordType'
import { ref } from 'vue'

type Props = {
    me: UserModel
}
defineProps<Props>()

const passwordData = ref<PasswordType>({
    current_password: undefined,
    new_password: undefined,
    new_password_confirmation: undefined
})

const save = () =>
    store.dispatch(`${EStoreModules.USER}/${EUserActions.CHANGE_PASSWORD}`, passwordData.value)
</script>

<template>
    <InformationBaseCard :label="$t('account.changePasswordTitle')">
        <form class="full-width">
            <StackLayout direction="column" :gap="16" class="full-width">
                <InputField
                    :label="$t('account.model.currentPassword')"
                    :modelValue="passwordData.current_password"
                    @change="passwordData.current_password = $event"
                    inputType="password"
                    v-bind:password-reveal="true"
                    class="full-width" />
                <InputField
                    :label="$t('account.model.password')"
                    :modelValue="passwordData.new_password"
                    @change="passwordData.new_password = $event"
                    inputType="password"
                    v-bind:password-reveal="true"
                    class="full-width" />
                <InputField
                    :label="$t('account.model.confirmPassword')"
                    :modelValue="passwordData.new_password_confirmation"
                    @change="passwordData.new_password_confirmation = $event"
                    inputType="password"
                    v-bind:password-reveal="true"
                    class="full-width" />
                <CreateButton
                    :loading="!!store.state.user.isLoading"
                    :label="$t('common.save')"
                    @click="save" />
            </StackLayout>
        </form>
    </InformationBaseCard>
</template>

<style lang="scss">
.profile-role-card {
    & .card-content {
        text-align: center;
        border: 1px solid $primary;
    }
}
</style>
