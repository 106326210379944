import i18n from '@/locales/i18n'
import {
    CREATE_PASSWORD_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    ONBOARDING_ROUTE,
    RESET_PASSWORD_ROUTE,
    SEND_LINK_PWD_ROUTE
} from '@/router/routes'
import type { RouteType } from '@/types/Route'
import LoginView from '@/views/LoginView/LoginView.vue'
import OnboardingViewVue from '@/views/OnboardingView/OnboardingView.vue'
import PasswordCreate from '@/views/PasswordView/PasswordCreate.vue'
import PasswordForgotForm from '@/views/PasswordView/PasswordForgotForm.vue'
import PasswordReset from '@/views/PasswordView/PasswordReset.vue'
import PasswordSendLinkView from '@/views/PasswordView/PasswordSendLinkView.vue'
import { ref } from 'vue'

const authenticationRoutes: RouteType[] = [
    {
        path: '/login',
        name: 'Login',
        component: LoginView,
        meta: {
            title: ref(i18n.global.t('authentication.title.title')),
            guestRoute: true
        }
    },
    {
        path: CREATE_PASSWORD_ROUTE,
        name: 'createPassword',
        component: PasswordCreate,
        meta: {
            title: ref(i18n.global.t('authentication.createPassword.title')),
            guestRoute: true
        }
    },
    {
        path: RESET_PASSWORD_ROUTE,
        name: 'resetPassword',
        component: PasswordReset,
        meta: {
            title: ref(i18n.global.t('authentication.title.resetPassword')),
            guestRoute: true
        }
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        name: 'forgotPasswordEmail',
        component: PasswordForgotForm,
        meta: {
            title: ref(i18n.global.t('authentication.forgotPassword.title')),
            guestRoute: true
        }
    },
    {
        path: SEND_LINK_PWD_ROUTE,
        name: 'sendLinkConfirmation',
        component: PasswordSendLinkView,
        meta: {
            title: ref(i18n.global.t('authentication.title.sendLinkConfirmation')),
            guestRoute: true
        }
    },
    {
        path: ONBOARDING_ROUTE,
        name: 'onboarding',
        component: OnboardingViewVue,
        meta: {
            title: ref(i18n.global.t('authentication.onboarding.title')),
            guestRoute: true
        }
    }
]

export default authenticationRoutes
