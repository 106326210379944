<script setup lang="ts">
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import {
    defaultDeviceTemplateKeysCreateModel,
    type DeviceTemplateKeysCreateModel
} from '@/types/models/DeviceTemplateKeysCreateModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { ref } from 'vue'
import NewConfigComponent from './newConfigKeyComponents/NewConfigComponent.vue'

type Props = {
    editPermission?: PermissionsEnums
    hasPermission?: boolean
}
defineProps<Props>()
type Emits = {
    (e: 'create', value: DeviceTemplateKeysModel): void
}
const emit = defineEmits<Emits>()

const data = ref<DeviceTemplateKeysCreateModel>({ ...defaultDeviceTemplateKeysCreateModel })

const handleCreateKey = () => {
    store
        .dispatch(
            `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.CREATE_DEVICE_TEMPLATE_KEY}`,
            {
                ...data.value,
                values: data.value.values?.filter(item => item),
                excluded: data.value.excluded?.filter(item => item)
            }
        )
        .then(res => {
            emit('create', res)
            data.value = defaultDeviceTemplateKeysCreateModel
        })
}

const handleChange = (value: any) => (data.value = { ...data.value, ...value })
</script>
<template>
    <NewConfigComponent
        :confirmLabel="$t('deviceConfig.templateKeys.buttons.createNewKey')"
        :data="data"
        :editPermission="editPermission"
        :hasPermission="hasPermission"
        :loading="!!store.state.deviceConfig.templateKeys.isLoading"
        @change="handleChange"
        @confirm="handleCreateKey" />
</template>
