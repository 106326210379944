import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import { getQueryParam } from '@/utils/routeUtils'

const currentPage = getQueryParam('page')
const pageSize = getQueryParam('per_page')

export type PaginationType = {
    page: number
    per_page: number
}

export type PaginationDataType<T> = {
    data: T
    current_page: number
    from: number
    last_page: number
    per_page: number
    to: number
    total: number

    isLoading?: boolean
}

export const defaultPaginationDataType: PaginationDataType<any> = {
    current_page: currentPage ? parseInt(currentPage) : 1,
    from: 0,
    last_page: 0,
    per_page: pageSize ? parseInt(pageSize) : EPaginationOptions.DEFAULT,
    to: 0,
    total: 0,
    data: []
}
