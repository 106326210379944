<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { DeviceConfigTemplateKey } from '@/types/device/DeviceConfigTemplateKey'
import { EKeyCategory, EKeyCategoryLabels } from '@/types/enum/KeyCategoryEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { computed, ref } from 'vue'
import DeviceConfigurationItem from './DeviceConfigurationItem.vue'

type Props = {
    data: Partial<DeviceModel>
    isInEditMode: boolean
    showAdvanced: boolean
    config: Record<string, DeviceConfigTemplateKey[]>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', data: Partial<DeviceModel>): void
    (e: 'refresh'): void
    (e: 'hasAdvancedConfig', value: boolean): void
}
const emit = defineEmits<Emits>()

const handleChange = (value: { [key: string]: ExtendedPrimitives | undefined }) => {
    emit('update', { config: { ...props.data.config, ...value } })
}

const informationsRef = ref()
const authenticationRef = ref()
const rfidRef = ref()
const hasError = computed(
    () =>
        informationsRef.value?.configurationHasError ||
        authenticationRef.value?.configurationHasError ||
        rfidRef.value?.configurationHasError
)
defineExpose({ hasError })
</script>

<template>
    <StackLayout direction="column" :gap="16" isResponsive class="full-width">
        <InformationBaseCard
            v-if="config[EKeyCategory.INFORMATIONS]"
            v-show="
                !!informationsRef?.configLength ||
                (!!informationsRef?.advancedConfigLength && showAdvanced)
            "
            :label="EKeyCategoryLabels[EKeyCategory.INFORMATIONS]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="informationsRef"
                :data="data.config"
                :template="config?.[EKeyCategory.INFORMATIONS]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>

        <InformationBaseCard
            v-if="config[EKeyCategory.AUTH]"
            v-show="
                authenticationRef?.configLength ||
                (authenticationRef?.advancedConfigLength && showAdvanced)
            "
            :label="EKeyCategoryLabels[EKeyCategory.AUTH]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="authenticationRef"
                :data="data.config"
                :template="config[EKeyCategory.AUTH]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>

        <InformationBaseCard
            v-if="config[EKeyCategory.RFID]"
            v-show="rfidRef?.configLength || (rfidRef?.advancedConfigLength && showAdvanced)"
            :label="EKeyCategoryLabels[EKeyCategory.RFID]"
            class="full-width fit-height">
            <DeviceConfigurationItem
                ref="rfidRef"
                :data="data.config"
                :template="config[EKeyCategory.RFID]"
                @change="handleChange"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced" />
        </InformationBaseCard>
    </StackLayout>
</template>
