<script setup lang="ts">
import HrefCreateButton from '@/components/Buttons/HrefCreateButton.vue'
import { FullTableLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import ClickableTypography from '@/components/Typography/ClickableTypography.vue'
import { moment } from '@/locales/langUtils'
import { EVENTS_ROUTES } from '@/router/routes'
import store from '@/store'
import { EEventActions } from '@/store/eventStore/EventStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { EConfirmDialog } from '@/types/enum/ConfirmDialogEnum'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdvancedEventFilters,
    defaultEventFilters,
    type AdvancedEventFilters,
    type EventFilters
} from '@/types/filters/EventFilters'
import type { EventModel } from '@/types/models/EventModel'
import { EEventsPerm } from '@/types/permissions/EventsPermissionsEnum'
import { DATE_FORMAT } from '@/utils/dateUtils'
import { isCurrentPageOverTotal } from '@/utils/paginationUtils'
import { addToQuery, getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import EventAdvancedFilters from './Table/EventAdvancedFilters.vue'
import EventTableFilters from './Table/EventTableFilters.vue'

const { t } = useI18n()
const router = useRouter()

const columns = {
    name: { field: 'name', label: t('event.table.eventName') },
    client_id: { field: 'name', label: t('event.table.clientId') },
    start_at: { label: t('event.table.startDate') },
    end_at: { label: t('event.table.endDate') }
}

const data = computed(() => store.state.event)
const filters = ref<EventFilters & Partial<AdvancedEventFilters>>({
    ...defaultEventFilters,
    ...defaultAdvancedEventFilters,
    ...getQueryFilters()
})
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)

const getEvents = () =>
    store.dispatch(`${EStoreModules.EVENT}/${EEventActions.FETCH_EVENTS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: getPageSize() ?? data.value.per_page,
        page: data.value.current_page
    })

const goToEventDetail = (event: EventModel) => router.push(EVENTS_ROUTES.buildUrl(event.id))
const goToEventCreate = () => router.push(EVENTS_ROUTES.createUrl)

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getEvents()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getEvents()
}

const deleteEvent = (event: EventModel) =>
    store
        .dispatch(`${EStoreModules.EVENT}/${EEventActions.DELETE_EVENT}`, {
            eventId: event.id,
            name: event.name
        })
        .then(() =>
            isCurrentPageOverTotal(
                data.value.total - 1,
                data.value.per_page,
                data.value.current_page,
                data.value.last_page
            )
                ? handleChangePageNumber(data.value.current_page - 1)
                : getEvents()
        )

const table = ref()

const handleFilters = (value: Partial<EventFilters & AdvancedEventFilters>) => {
    filters.value = { ...filters.value, ...value }
    addToQuery({ ...value, page: 1 })
    data.value.current_page = 1
    getEvents()
}
const handleAdvancedFilters = (value: Partial<EventFilters & AdvancedEventFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getEvents()
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    addToQuery({ ...advancedFilters.value, page: 1 })
    getEvents()
}

const advancedFilters = ref<Partial<AdvancedEventFilters>>({ ...defaultAdvancedEventFilters })
const filtersTags = computed(() =>
    Object.entries({
        ongoing: filters.value.ongoing,
        upcoming: filters.value.upcoming,
        finished: filters.value.finished
    }).reduce(
        (prev: FilterTagModel[], [key, value]) =>
            value ? [...prev, { name: key, value: !!value }] : prev,
        []
    )
)

const handleRemoveTag = (value: FilterTagModel) => {
    filters.value = { ...filters.value, [value.name]: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name]: undefined }
    addToQuery({ ...filters.value, page: 1 })
    getEvents()
}

onBeforeUnmount(() => {
    data.value.current_page = 1
})
</script>

<template>
    <PermissionWrapper :permission="EEventsPerm.EVENTS_EDIT">
        <HrefCreateButton
            :href="EVENTS_ROUTES.createUrl"
            :label="$t('event.createEvent')"
            @click="goToEventCreate" />
    </PermissionWrapper>
    <FullTableLayout
        showAdvancedFilters
        :sortBy="sort"
        @search="handleSearch"
        @changeSortBy="handleChangeSortBy"
        translationPath="event.filters"
        :advancedFilters="filtersTags"
        @removeTag="handleRemoveTag">
        <template #filters>
            <EventTableFilters :filters="filters" @change="handleFilters" />
        </template>

        <template #table>
            <BaseTable v-bind:ref="table" :data="data.data.events" :loading="data.isLoading">
                <template #columns>
                    <TableColumn field="name" :label="columns['name'].label" v-slot="props">
                        <ClickableTypography
                            :label="props.row.name"
                            :href="EVENTS_ROUTES.buildUrl(props.row.id)" />
                    </TableColumn>
                    <TableColumn field="start_at" :label="columns['start_at'].label" v-slot="props">
                        {{ moment(props.row.start_at).format(DATE_FORMAT).toString() }}
                    </TableColumn>
                    <TableColumn field="end_at" :label="columns['end_at'].label" v-slot="props">
                        {{ moment(props.row.end_at).format(DATE_FORMAT).toString() }}
                    </TableColumn>
                    <TableActionColumn
                        :type="EConfirmDialog.ARCHIVE"
                        :editPermission="EEventsPerm.EVENTS_VIEW"
                        :deletePermission="EEventsPerm.EVENTS_DELETE"
                        @goTo="goToEventDetail"
                        @delete="deleteEvent"
                        :editButtonHref="EVENTS_ROUTES.baseUrl" />
                </template>
            </BaseTable>
        </template>
        <template #pagination>
            <TablePagination
                :currentPage="data.current_page"
                :lastPage="data.last_page"
                :total="data.total"
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </template>

        <template #dialogContent>
            <EventAdvancedFilters :filters="advancedFilters" @change="handleAdvancedFilters" />
        </template>
    </FullTableLayout>
</template>

<style scoped>
.centered-pagination {
    padding: 8px;
}
</style>
