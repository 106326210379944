<script setup lang="ts">
import { InputField } from '@/components/Fields'
import GuestLayout from '@/components/Layouts/GuestLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import { LOGIN_URL } from '@/router'
import { SEND_LINK_PWD_ROUTE } from '@/router/routes'
import store from '@/store'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

type Props = {
    primaryLabel: string
    title: string
}

type Emits = {
    (e: 'clickSecondary'): void
}

defineProps<Props>()
defineEmits<Emits>()

const isLoading = computed(() => store.state.authentication.isLoading)
const email = ref('')
const submitted = ref(false)

const submitForm = () => {
    submitted.value = true
    if (!email.value) return // TODO : Alerte
    store
        .dispatch(
            `${EStoreModules.AUTHENTICATION}/${EAuthActions.FORGOT_PASSWORD}`,
            email.value.toString()
        )
        .then(res => {
            if (res.status === 200)
                router.push({
                    path: SEND_LINK_PWD_ROUTE,
                    query: { email: email.value }
                })
        })
}

const goBack = () => router.replace(LOGIN_URL)

const emailError = computed(() => submitted.value && !email.value)
</script>

<template>
    <FullScreenLoader :isLoading="isLoading" />
    <GuestLayout
        :title="$t('authentication.forgotPassword.title')"
        :primaryLabel="$t('authentication.forgotPassword.sendEmail')"
        :secondaryLabel="$t('authentication.backToLogin')"
        @clickSubmit="submitForm"
        @clickSecondary="goBack"
        class="password-guest-layout">
        <template #content>
            <StackLayout :gap="16" direction="column">
                <span>
                    {{ $t('authentication.forgotPassword.contentTitle') }}
                </span>
                <InputField
                    :label="$t('authentication.forgotPassword.email')"
                    v-model="email"
                    @change="value => (email = value)"
                    :placeholder="$t('authentication.forgotPassword.email')"
                    inputType="email"
                    required
                    :hasError="emailError" />
                <span>
                    {{ $t('authentication.forgotPassword.content') }}
                </span>
            </StackLayout>
        </template>
    </GuestLayout>
</template>
