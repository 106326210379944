<script setup lang="ts">
import { PrimaryButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import type { LinkDevice } from '@/types/components/LinkActions/LinkDevice'
import { computed, ref } from 'vue'
import DeviceSelector from '../../Selector/components/DeviceSelector.vue'
import OrganizationSelector from '../../Selector/components/OrganizationSelector.vue'

type Props = {
    title: string
    confirmLabel: string
    organizationId?: number
    deviceId?: number
}
const { organizationId, deviceId } = defineProps<Props>()
type Emits = {
    (e: 'confirm', value: Partial<LinkDevice>): void
}
const emit = defineEmits<Emits>()

const isSubmitted = ref(false)
const open = ref(false)

const data = computed({
    get: () => ({
        organizationId: organizationId,
        deviceId: deviceId
    }),
    set: value => value
})

const confirmAction = () => {
    isSubmitted.value = true
    if (data.value.deviceId === undefined || data.value.organizationId === undefined) return
    emit('confirm', data.value)
    data.value.organizationId = organizationId
    data.value.deviceId = deviceId
    isSubmitted.value = false
    open.value = false
}
</script>

<template>
    <PrimaryButton :label="confirmLabel" @click="open = true" class="full-width" />
    <BaseDialog
        :title="title"
        :open="open"
        @confirm="confirmAction"
        @close="open = false"
        class="dialog-with-dropdown modal-selector-full-width">
        <template #content>
            <StackLayout direction="column" class="full-width" :gap="16">
                <DeviceSelector
                    v-if="!deviceId"
                    :label="$t('organization.model.deviceId')"
                    :selectedDevice="data.deviceId?.toString()"
                    @select="value => (data.deviceId = value.device_id)"
                    class="full-width" />
                <OrganizationSelector
                    v-if="!organizationId"
                    :selectedOrganization="data.organizationId?.toString()"
                    @select="value => (data.organizationId = value.id)" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>
