import i18n from '@/locales/i18n'
import { DETECTION_ROUTE, EVENTS_ROUTE } from '@/router/routes'
import type { RouteType } from '@/types/Route'
import { EDetectionsPerm } from '@/types/permissions/DetectionsPermissionsEnum'
import DetectionsTableView from '@/views/DetectionsView/DetectionsTableView.vue'
import { ref } from 'vue'

export enum EDetectionsRoutesNames {
    DETECTION_TABLE = 'detectionTable'
}
export const EDetectionsRoutesNamesValues = [EDetectionsRoutesNames.DETECTION_TABLE]

const routes: RouteType[] = [
    {
        path: `${EVENTS_ROUTE}${DETECTION_ROUTE}`,
        name: EDetectionsRoutesNames.DETECTION_TABLE,
        component: DetectionsTableView,
        meta: {
            title: ref(i18n.global.t('menu.detections')),
            permission: EDetectionsPerm.DETECTIONS_VIEW
        }
    }
    /* {
        path: `${EVENTS_ROUTE}${DETECTION_ROUTE}/:id`,
        name: 'detectionDetail',
        component: DetectionsTableView,
        meta: {
            title: ref(i18n.global.t('menu.detections',
            permission: EDetectionsPerm.DETECTIONS_VIEW
        }
    } */
]

export default routes
