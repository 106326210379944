<script setup lang="ts">
import type { TabType } from '@/types/TabType'
import { ref, watch } from 'vue'
import { useRoute, type LocationQueryValue } from 'vue-router'
import { addToQuery } from '../../utils/queryUtils'
import TabItem from './TabItem.vue'

type Props = {
    tab?: number
    tabs: TabType[]
}

const props = defineProps<Props>()
type Emits = {
    (e: 'change', value?: string | number | LocationQueryValue[]): void
}
const emit = defineEmits<Emits>()

const route = useRoute()

const activeTab = ref(props.tab ?? route.query?.tab?.toString() ?? 0)

watch(
    () => activeTab.value,
    value => handleChangeTab(value)
)
watch(
    () => route.query.tab,
    newValue => (activeTab.value = parseInt((newValue ?? 0)?.toString()) ?? 0)
)
const handleChangeTab = (value: number | string) => {
    addToQuery({ tab: value.toString() ?? 0 })
    emit('change', value)
    activeTab.value = value ?? 0
}
</script>

<template>
    <b-tabs v-if="tabs.length" v-model="activeTab" class="flex flex-column">
        <TabItem v-for="(item, key) in tabs" :key="key" :value="key.toString()" :label="item.label">
            <slot :name="item.id" />
        </TabItem>
    </b-tabs>
</template>

<style scoped lang="scss">
.b-tabs {
    & :deep(.tabs) {
        & li a {
            color: rgba($primary, 65%);
            &:hover {
                color: rgba($primary, 85%);
                background-color: var(--color-background-secondary);
            }
            &:focus {
                color: rgba($primary, 85%);
                border-bottom-color: $primary;
            }
        }
        & li.is-active a {
            border-bottom-color: $primary;
            color: $primary;
        }
    }
    & :deep(.tabs) {
        justify-content: space-evenly;

        li {
            width: 100%;
        }
    }
    & :deep(.tab-content) {
        padding: 1rem 0 0 0;
    }
}
</style>
