export const detectionsFr = {
    api: {
        error: {
            exportDetections: "Erreur pendant l'export des détections",
            fetchDetections: 'Erreur pendant la récupération des détections'
        },
        success: {
            exportDetections: 'Le fichier a bien été généré',
            fetchDetections: 'Détections correctement récupérées'
        }
    },
    filters: {
        bib: 'Bib',
        checkpoint: 'Checkpoint',
        startDate: 'A partir du',
        start_at: 'A partir du',
        end_at: "Jusqu'au",
        endDate: "Jusqu'au",
        device: 'Appareil',
        event: 'Évènement',
        eventId: 'Event ID',
        timerId: 'Timer ID'
    },
    model: {
        bib: 'Bib',
        checkpointId: 'Checkpoint',
        deviceId: "ID de l'appareil",
        eventFilter: "ID de l'évènement",
        id: 'ID',
        timerFilter: 'ID du chronométreur',
        timestamp: 'Date'
    },
    noDetectionFound: 'Aucune détection trouvée',
    table: {
        bib: 'Bib',
        checkpointName: 'Checkpoint',
        eventName: 'Évènement',
        detections: 'Détections',
        deviceId: "ID de l'appareil",
        deviceName: "Nom de l'appareil",
        eventFilter: "ID de l'évènement",
        id: 'ID',
        timerFilter: 'ID du chronométreur',
        timestamp: 'Date'
    }
}
