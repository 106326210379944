<script setup lang="ts">
import type { SelectorObject } from '@/types/components/SelectorObject'
import { computed } from 'vue'
import StackLayout from '../Layouts/StackLayout.vue'
import TypographyItem from '../Typography/TypographyItem.vue'

const props = defineProps<{
    label?: string
    options: SelectorObject[]
    modelValue: number
}>()

const localValue = computed({
    get: () => props.modelValue,
    set: value => emit('change', value)
})

type Emits = {
    (e: 'change', value: number): void
}

const emit = defineEmits<Emits>()
</script>

<template>
    <StackLayout :gap="4" class="flex-wrap">
        <b-radio
            v-for="(value, index) in props.options"
            :key="index"
            v-model="localValue"
            @input="(value: number) => (localValue = value)"
            :native-value="value.value">
            <TypographyItem :label="value.name" />
        </b-radio>
    </StackLayout>
</template>
