<script setup lang="ts">
import { InputField } from '@/components/Fields'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import { GridLayout } from '@/components/Layouts'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'

type Props = {
    filters: DetectionFilters
    deviceOptions?: AssociatedDeviceModel[]
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
    (e: 'associates', value?: AssociatedDeviceModel[]): void
}
const emit = defineEmits<Emits>()

const gridSize = useGridSize()

const handleChange = (value: Partial<DetectionModel>) => {
    if (!value) return
    emit('change', value)
}
</script>

<template>
    <GridLayout :maxItemPerRow="gridSize" :gap="8" class="full-width flex-wrap" isResponsive>
        <EventCheckpointAutocomplete
            :event_id="filters.event_id"
            :checkpoint_id="filters.checkpoint_id"
            keepEventInStorage
            @change="handleChange($event)"
            @associates="$emit('associates', $event)" />
        <AutocompleteField
            :label="$t('detection.filters.device')"
            :modelValue="deviceOptions?.find(item => item.id === filters.device_id)?.name"
            :options="deviceOptions ?? []"
            :disabled="!filters.checkpoint_id"
            @select="$emit('change', { device_id: ($event as DetectionModel)?.id })" />
        <InputField
            :label="$t('detection.filters.bib')"
            :modelValue="filters.bib"
            :prefix="$t('unit.number')"
            @change="handleChange({ bib: $event })" />
    </GridLayout>
</template>
