<script setup lang="ts">
import CheckboxField from '@/components/Fields/CheckboxField.vue'
import TicketTablePage from '@/components/TicketComponents/TicketTablePage.vue'
import { TECHNICIAN_TICKETS_ROUTES } from '@/router/routing/technicianRouting/technicianRoutes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { ETechnicianActions } from '@/store/technicianStore/TechnicianStoreTypes'
import { ETicketActions } from '@/store/ticketStore/TicketStoreTypes'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { TechnicianTicketAdvancedFilters } from '@/types/filters/TechnicianTicketFilters'
import type { TicketFilters } from '@/types/filters/TicketFilters'
import type { TicketModel } from '@/types/models/TicketModel'
import { ETechnicianTicketsPerm } from '@/types/permissions/TechnicianPermissionsEnum'
import { isCurrentPageOverTotal } from '@/utils/paginationUtils'
import { addToQuery, getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import TechnicianTicketsFilters from './Table/TechnicianTicketsFilters.vue'

const { t } = useI18n()
const router = useRouter()

const data = computed(() => store.state.ticket)

const filters = ref<Partial<TicketFilters>>({
    ...getQueryFilters()
})
const advancedFilters = ref<Partial<TicketFilters>>()
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getTickets = () =>
    store.dispatch(`${EStoreModules.TECHNICIAN}/${ETechnicianActions.FETCH_TECHNICIAN_TICKETS}`, {
        ...filters.value,
        sort: getSortByKey(sort.value),
        page: data.value.current_page,
        per_page: getPageSize() ?? data.value.per_page
    })

const handleChangeFilters = (value: Partial<TicketModel>) => {
    filters.value = { ...filters.value, filters: { ...filters.value.filters, ...value } }
    addToQuery({ ...filters.value, page: 1 })
    data.value.current_page = 1
    getTickets()
}
const handleAdvancedFilters = (value: Partial<TechnicianTicketAdvancedFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })

const goToDetail = (ticket: TicketModel) =>
    router.push(TECHNICIAN_TICKETS_ROUTES.buildUrl(ticket.id))

const goToCreate = () => router.push(TECHNICIAN_TICKETS_ROUTES.createUrl)

const deleteTicket = (ticket: TicketModel) =>
    store
        .dispatch(`${EStoreModules.TICKET}/${ETicketActions.FETCH_TICKETS}`, {
            ticketId: ticket.id
        })
        .then(() =>
            isCurrentPageOverTotal(
                data.value.total - 1,
                data.value.per_page,
                data.value.current_page,
                data.value.last_page
            )
                ? handleChangePageNumber(data.value.current_page - 1)
                : getTickets()
        )

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getTickets()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getTickets()
}

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getTickets()
}
</script>

<template>
    <TicketTablePage
        :data="{ ...data, data: data.data.tickets }"
        showAdvancedFilters
        :filters="filters"
        :sortBy="sort"
        :loading="data.isLoading"
        :editPermission="ETechnicianTicketsPerm.TECHNICIAN_TICKETS_EDIT"
        :deletePermission="ETechnicianTicketsPerm.TECHNICIAN_TICKETS_DELETE"
        :editButtonHref="TECHNICIAN_TICKETS_ROUTES.baseUrl"
        :createButtonHref="TECHNICIAN_TICKETS_ROUTES.createUrl"
        @delete="deleteTicket"
        @goToCreate="goToCreate"
        @goToDetail="goToDetail"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeFilters="handleChangeFilters"
        @changeSortBy="handleChangeSortBy">
        <template #filters>
            <TechnicianTicketsFilters
                :filters="filters.filters"
                @change="handleChangeFilters({ ...$event })" />
        </template>
        <template #advancedFilters>
            <CheckboxField
                :label="$t('technician.ticket.filter.archived')"
                :value="filters?.archived"
                @click="handleAdvancedFilters({ archived: $event })"
                class="responsive-width" />
        </template>
    </TicketTablePage>
</template>
