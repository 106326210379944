import i18n from '@/locales/i18n'
import roleService from '@/services/role.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { RoleModel } from '@/types/models/RoleModel'
import { isForbiddenOrNotFound } from '@/utils/errorsUtils'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { ERoleActions, ERoleMutations, type RoleState } from './RoleStoreTypes'

const state: Ref<PaginationDataType<RoleState>> = ref({
    data: {
        roles: []
    },
    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
    isLoading: false
})

const getters = {
    getRoles: () => state.value.data.roles,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [ERoleActions.FETCH_ROLE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        roleId: string
    ) {
        commit(ERoleMutations.SET_LOADING, true)
        return await roleService
            .getRole(roleId)
            .then(resp => {
                commit(ERoleMutations.SET_ROLE, resp.data)
                setDocumentAndTitle(resp.data.name)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: ERoleActions.FETCH_ROLE
                })
            })
            .finally(() => commit(ERoleMutations.SET_LOADING, false))
    },

    async [ERoleActions.FETCH_ROLES](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(ERoleMutations.SET_LOADING, true)
        return await roleService
            .getRoles(params)
            .then(resp => {
                commit(ERoleMutations.SET_ROLES, resp.data)
                commit(ERoleMutations.SET_TOTAL, resp)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: ERoleActions.FETCH_ROLES
                })
            })
            .finally(() => {
                commit(ERoleMutations.SET_LOADING, false)
            })
    },

    async [ERoleActions.CREATE_ROLE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        roleData: RoleModel
    ) {
        commit(ERoleMutations.SET_LOADING, true)
        const res = await roleService
            .createRole(roleData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: roleData.name,
                    key: ERoleActions.CREATE_ROLE
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: roleData.name,
                    key: ERoleActions.CREATE_ROLE
                })
            })
            .finally(() => commit(ERoleMutations.SET_LOADING, false))
        return res
    },

    async [ERoleActions.UPDATE_ROLE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { roleId, roleData }: { roleId: string; roleData: RoleModel }
    ) {
        commit(ERoleMutations.SET_LOADING, true)
        return await roleService
            .updateRole(roleId, roleData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: roleData.name,
                    key: ERoleActions.UPDATE_ROLE
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: roleData.name,
                    key: ERoleActions.UPDATE_ROLE
                })
            })
            .finally(() => commit(ERoleMutations.SET_LOADING, false))
    },

    async [ERoleActions.DELETE_ROLE](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { roleId, name }: { roleId: string; name: string }
    ) {
        commit(ERoleMutations.SET_LOADING, true)
        return roleService
            .deleteRole(roleId)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: ERoleActions.DELETE_ROLE
                })
                return res
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name,
                    key: ERoleActions.DELETE_ROLE
                })
            })
            .finally(() => commit(ERoleMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`role.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`role.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [ERoleMutations.SET_ROLES](_: RoleState, roles: RoleModel[]) {
        state.value.data.roles = roles
    },

    [ERoleMutations.SET_ROLE](_: RoleState, role: RoleModel) {
        state.value.data.role = role
    },

    [ERoleMutations.SET_TOTAL](_: RoleState, data: PaginationDataType<RoleModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },

    [ERoleMutations.SET_LOADING](_: RoleState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
