import i18n from '@/locales/i18n'
import TokenService from '@/services/token.service'
import userService from '@/services/user.service'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { UserModel } from '@/types/models/UserModel'
import type { PaginationDataType } from '@/types/PaginationType'
import { isForbiddenOrNotFound } from '@/utils/errorsUtils'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import store from '..'
import type { PasswordType } from '../../types/PasswordType'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { ELoadingActions } from '../loadingStore/LoadingStoreTypes'
import { EStoreModules } from '../storeType'
import { EUserActions, EUserMutations, type UserState } from './UserStoreTypes'

const state: Ref<PaginationDataType<UserState>> = ref({
    data: { users: [] },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getUsers: () => state.value.data.users,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EUserActions.FETCH_USER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        userId: number
    ) {
        commit(EUserMutations.SET_LOADING, true)
        return await userService
            .getUser(userId)
            .then(resp => {
                commit(EUserMutations.SET_USER, resp.data)
                setDocumentAndTitle(resp.data.name)
                return resp.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EUserActions.FETCH_USER
                })
            })
            .finally(() => commit(EUserMutations.SET_LOADING, false))
    },
    async [EUserActions.FETCH_USERS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: any
    ) {
        commit(EUserMutations.SET_LOADING, true)
        return await userService
            .getUsers(params)
            .then(data => {
                commit(EUserMutations.SET_USERS, data.data)
                commit(EUserMutations.SET_TOTAL, data)
                return data.data
            })
            .catch(error => {
                if (isForbiddenOrNotFound(error)) return
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EUserActions.FETCH_USERS
                })
            })
            .finally(() => commit(EUserMutations.SET_LOADING, false))
    },
    async [EUserActions.CREATE_USER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        userData: any
    ) {
        commit(EUserMutations.SET_LOADING, true)
        return await userService
            .createUser(userData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: userData.name,
                    key: EUserActions.CREATE_USER
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: userData.name,
                    key: EUserActions.CREATE_USER
                })
            })
            .finally(() => commit(EUserMutations.SET_LOADING, false))
    },

    async [EUserActions.UPDATE_USER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { userId, data }: { userId: number; data: UserModel }
    ) {
        commit(EUserMutations.SET_LOADING, true)
        return await userService
            .updateUser(userId, data)
            .then(resp => {
                commit(EUserMutations.SET_USER, resp.data)
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EUserActions.UPDATE_USER
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: data.name,
                    key: EUserActions.UPDATE_USER
                })
            })
            .finally(() => commit(EUserMutations.SET_LOADING, false))
    },

    async [EUserActions.CHANGE_PASSWORD](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        passwordData: PasswordType
    ) {
        commit(EUserMutations.SET_LOADING, true)
        return await userService
            .changePassword(passwordData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    key: EUserActions.CHANGE_PASSWORD
                })
                return resp.data
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    key: EUserActions.CHANGE_PASSWORD
                })
            })
            .finally(() => commit(EUserMutations.SET_LOADING, false))
    },

    async [EUserActions.DELETE_USER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        user: UserModel
    ) {
        commit(EUserMutations.SET_LOADING, true)
        return await userService
            .deleteUser(user.id)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: user.name,
                    key: EUserActions.DELETE_USER
                })
                return res
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, {
                    name: user.name,
                    key: EUserActions.DELETE_USER
                })
            })
            .finally(() => commit(EUserMutations.SET_LOADING, false))
    },

    async [EUserActions.IMPERSONATE_USER]({ dispatch }: { dispatch: Dispatch }, user: UserModel) {
        store.dispatch(ELoadingActions.IS_LOADING)
        return await userService
            .impersonateUser(user.id)
            .then(res => {
                TokenService.swapImpersonateUser(res.data)
                dispatch(EHandler.HANDLE_SUCCESS, { key: 'impersonate', name: user.name })
                dispatchEvent(new CustomEvent('impersonate'))
            })
            .catch(() => {
                throw dispatch(EHandler.HANDLE_ERROR, { key: 'impersonate' })
            })
            .finally(() => store.dispatch(ELoadingActions.NOT_IS_LOADING))
    },
    [EUserActions.DEIMPERSONATION_USER]({ dispatch }: { dispatch: Dispatch }) {
        store.dispatch(ELoadingActions.IS_LOADING)
        TokenService.swapImpersonateUser()
        dispatchEvent(new CustomEvent('impersonate'))
        dispatch(EHandler.HANDLE_SUCCESS, { key: 'disincarnateUser' }).then(() =>
            store.dispatch(ELoadingActions.NOT_IS_LOADING)
        )
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`user.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`user.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EUserMutations.SET_USERS](_: UserState, users: UserModel[]) {
        state.value.data.users = users
    },

    [EUserMutations.SET_USER](_: UserState, user: UserModel) {
        state.value.data.user = user
    },

    [EUserMutations.SET_TOTAL](_: UserState, data: PaginationDataType<UserModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },

    [EUserMutations.SET_LOADING](_: UserState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
