<script setup lang="ts">
import DeleteButton from '@/components/Buttons/DeleteButton.vue'
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import { BaseCard } from '@/components/Cards'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { ClickableTypography, TypographyItem } from '@/components/Typography'
import TitleTypography from '@/components/Typography/TitleTypography.vue'
import DetectionWrapper from '@/components/Wrappers/DetectionWrapper.vue'
import { DEVICE_ROUTES } from '@/router/routes'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { useRouter } from 'vue-router'

type Props = {
    data: CheckpointModel
    detections?: DetectionModel[]
    deletePermission?: PermissionsEnums
    editPermission?: PermissionsEnums
    href: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'delete', checkpoint: CheckpointModel): void
    (e: 'click', checkpoint?: CheckpointModel): void
}
const emit = defineEmits<Emits>()

const router = useRouter()
const handleGoTo = () => {
    router.replace(props.href)
    emit('click')
}
</script>

<template>
    <BaseCard class="flex primary-border color-text pointer device-card" v-bind="$attrs">
        <template #title>
            <TitleTypography :title="data.name" />
        </template>
        <template #content>
            <StackLayout
                v-if="data.associates?.length"
                direction="column"
                :gap="4"
                class="full-width"
                isResponsiveSmallScreen>
                <StackLayout
                    v-for="(device, key) in data.associates"
                    :key="key"
                    direction="column"
                    :gap="4"
                    class="full-width"
                    isResponsiveSmallScreen>
                    <BaseCard class="checkpoint-device-card">
                        <template #content>
                            <ClickableTypography
                                :label="device.name"
                                :href="DEVICE_ROUTES.buildUrl(device.device_id)" />
                        </template>
                    </BaseCard>
                </StackLayout>
                <StackLayout direction="column" class="device-card-footer">
                    <DetectionWrapper :detections="detections ?? []" class="detection-class" />
                </StackLayout>
            </StackLayout>
            <TypographyItem v-else :label="$t('event.checkpoint.noDeviceAssociated')" />
        </template>
        <template #footer>
            <StackLayout class="full-width">
                <PermissionWrapper :permission="deletePermission">
                    <DeleteButton class="full-width left-button" @click="$emit('delete', data)" />
                </PermissionWrapper>
                <PermissionWrapper :permission="editPermission">
                    <SecondaryButton
                        @click="handleGoTo"
                        :label="$t('common.edit')"
                        class="right-button full-width" />
                </PermissionWrapper>
            </StackLayout>
        </template>
    </BaseCard>
</template>

<style scoped lang="scss">
.device-card {
    flex-direction: column;

    & :deep(.card-header-title) {
        & .title {
            font-size: large;
            color: var(--color-text);
        }
    }

    & :deep(.card-header-title .subtitle) {
        color: var(--color-text-secondary);
    }

    & :deep(.card-content) {
        height: 100%;

        & hr {
            margin-left: unset;
            margin-right: unset;
            border-style: unset;
        }
    }

    & :deep(.card-footer) {
        flex: auto;
    }

    & :deep(.card-header-title),
    & :deep(.card-content) {
        padding: 0.5em;
    }
}

.checkpoint-device-card {
    cursor: default;
}

.detection-class {
    & :deep(.no-detection) {
        white-space: nowrap;
    }
}
</style>
