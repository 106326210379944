<script setup lang="ts">
import type { Direction } from '@/types/constants/Direction'
import { isMobile, isMobileEvent, isSmallScreen, isSmallScreenEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'

type Props = {
    direction?: Direction
    gap?: number
    wrap?: boolean
    isResponsive?: boolean | Direction
    isResponsiveSmallScreen?: boolean | Direction
}
const props = defineProps<Props>()

const isResponsiveSmallScreen = ref(props.isResponsiveSmallScreen && isSmallScreen())
isSmallScreenEvent(e =>
    props.isResponsiveSmallScreen ? (isResponsiveSmallScreen.value = e.matches) : undefined
)
const isResponsive = ref(props.isResponsive && isMobile())
isMobileEvent(e => (props.isResponsive ? (isResponsive.value = e.matches) : undefined))

const gapValue = computed(() => `${props.gap ?? 0}px`)
const directionRef = computed<Direction>(() =>
    isResponsiveSmallScreen.value || isResponsive.value
        ? typeof props.isResponsive === 'string' ||
          typeof props.isResponsiveSmallScreen === 'string'
            ? ((props.isResponsive ?? props.isResponsiveSmallScreen) as Direction)
            : 'column'
        : props.direction ?? 'row'
)

const stackRef = ref<HTMLDivElement | null>(null)

defineExpose({ stackRef })
</script>

<template>
    <div ref="stackRef" :class="`stack ${wrap ? 'flex-wrap' : ''}`" v-bind="$attrs">
        <slot />
    </div>
</template>

<style scoped>
.stack {
    display: flex;
    flex-direction: v-bind(directionRef);
    gap: v-bind(gapValue);
}
</style>
