<script setup lang="ts">
import { CreateButton, SecondaryButton } from '@/components/Buttons'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import checkpointApi from '@/services/checkpoint.service'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { ECheckpointsPerm } from '@/types/permissions/CheckpointsPermissionsEnum'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import CheckpointAssociatedDeviceCard from './components/CheckpointAssociatedDeviceCard.vue'
import CheckpointDeviceInput from './components/CheckpointDeviceInput.vue'

const { t } = useI18n()
const route = useRoute()
const eventId = computed(() => parseInt(route.params.id.toString()))
const checkpointId = computed(() => parseInt(route.params.checkpointId.toString()))

type Props = {
    associates: AssociatedDeviceModel[]
}
defineProps<Props>()
const emit = defineEmits<{ (e: 'refresh'): void }>()

const deviceId = ref<number | undefined>()

const submitted = ref(false)
const isInEditMode = ref(false)
const isLoading = ref(false)

const associateDevice = () => {
    if (isInEditMode.value) {
        submitted.value = true
        if (!deviceId.value) return // TODO : Alerte
        isLoading.value = true
        return checkpointApi
            .associateDeviceToCheckpoint(eventId.value, checkpointId.value, deviceId.value)
            .then(res => {
                if (res.status === 204) emit('refresh')
                isInEditMode.value = false
                submitted.value = false
                deviceId.value = undefined
            })
            .finally(() => (isLoading.value = false))
    }
    isInEditMode.value = true
}
const label = computed(() =>
    t(isInEditMode.value ? 'checkpoint.detail.associateDevice' : 'checkpoint.detail.associates')
)
</script>

<template>
    <FullScreenLoader :isLoading="isLoading" />
    <InformationBaseCard :label="$t('checkpoint.title.associateDeviceTitle')">
        <StackLayout direction="column" :gap="16" class="full-width">
            <StackLayout direction="column" :gap="8">
                <StackLayout v-if="associates.length" direction="column" :gap="8">
                    {{ $t('checkpoint.model.associates') }}
                    <StackLayout direction="row" :gap="8" wrap>
                        <CheckpointAssociatedDeviceCard
                            v-for="(device, key) in associates"
                            :key="key"
                            @refresh="$emit('refresh')"
                            :device="device" />
                    </StackLayout>
                </StackLayout>
                <span v-else>{{ $t('checkpoint.detail.noDeviceAssociated') }}</span>
            </StackLayout>
            <PermissionWrapper :permission="ECheckpointsPerm.CHECKPOINTS_EDIT">
                <CheckpointDeviceInput
                    :isInEditMode="isInEditMode"
                    :associates="associates"
                    @select="deviceId = $event?.device_id"
                    class="align-self-end" />
                <StackLayout
                    direction="row"
                    :gap="8"
                    class="align-self-end"
                    isResponsiveSmallScreen>
                    <SecondaryButton
                        v-if="isInEditMode"
                        :label="$t('common.cancel')"
                        @click="isInEditMode = false" />
                    <CreateButton :loading="isLoading" :label="label" @click="associateDevice" />
                </StackLayout>
            </PermissionWrapper>
        </StackLayout>
    </InformationBaseCard>
</template>
